import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

const Container = styled(Skeleton)`
  margin-top: ${({ margintop }) => margintop || 'unset'};
  margin-bottom: ${({ marginbottom }) => marginbottom || 'unset'};
  ${({ height }) => (height
    ? css`
      span {
        height: ${height};
      }
    ` : '')}
`;

const StyledSkeleton = ({ margintop, marginbottom, height, ...props }) => (
  <Container margintop={margintop} marginbottom={marginbottom} height={height}>
    <Skeleton {...props} />
  </Container>
);

export default StyledSkeleton;

import RoutesEnum from 'src/router/enums';

const getItems = (t, history, location) => [
  {
    name: t('MENU_ITEM_HOME'),
    icon: 'home',
    selected: location?.pathname === RoutesEnum.main.view,
    onClick: () => history.push(RoutesEnum.main.view),
  },
  {
    name: t('MENU_ITEM_DOCUMENTATION'),
    documents: true,
    icon: 'file',
    selected: location?.pathname === RoutesEnum.documentation.view || location?.pathname?.includes('documentation/'),
    onClick: () => history.push(RoutesEnum.documentation.view),
  },
  {
    name: t('MENU_ITEM_ADMIN_USERS'),
    icon: 'settings',
    admin: true,
    selected: location?.pathname === RoutesEnum.adminUsers.view || location?.pathname?.includes('person/'),
    onClick: () => history.push(RoutesEnum.adminUsers.view),
  },
];

const getFooterItems = (t, history, fullName, location, dispatch) => [
  {
    name: fullName,
    icon: 'user',
    onClick: () => history.push(RoutesEnum.profile.view),
    selected: location?.pathname === RoutesEnum.profile.view,
  },
  {
    name: t('MENU_FOOTER_ACCOUNTS'),
    icon: 'shield',
    onClick: () => history.push(RoutesEnum.accounts.view),
    selected: location?.pathname === RoutesEnum.accounts.view,
  },
  {
    name: t('MENU_FOOTER_FAQ'),
    icon: 'question-circle-light',
    onClick: () => history.push(RoutesEnum.faq.view),
    selected: location?.pathname === RoutesEnum.faq.view,
  },
  {
    name: t('MENU_FOOTER_CCLA'),
    icon: 'globe-solid',
    onClick: () => window.open('https://ccla.co.uk/'),
  },
  {
    name: t('MENU_FOOTER_PRIVACY_POLICY'),
    icon: 'privacy-policy',
    onClick: () => window.open('https://www.ccla.co.uk/privacy-policy'),
  },
  {
    name: t('MENU_FOOTER_LOGOUT'),
    icon: 'logout',
    onClick: () => dispatch.auth.SET_PROP({ showLogoutModal: true }),
  },
];

function getMenuItems(t, history, location, fullName, dispatch) {
  return {
    items: getItems(t, history, location),
    footer: getFooterItems(t, history, fullName, location, dispatch),
  };
}

const MENU = {
  getItems,
  getFooterItems,
  getMenuItems,
};

export default MENU;

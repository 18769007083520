import { createGlobalStyle } from 'styled-components';
import { themeHelper } from 'src/utils';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html {
    -webkit-font-smoothing: antialiased;
  }
  .sidebar{
    svg{
      height: 16px;
      width: 16px;
    }
    div{
      font-family: ${themeHelper.get('font.primary')};
      display: flex;
      align-items: center;
      span{
        margin-top: 6px;
      }
    }
    .footeritem{
      .label{
        margin-top: 5px;
      }
    }
  }

  body {
    font-family: ${themeHelper.get('font.primary')};
    background: ${({ isAuthenticated }) => (isAuthenticated ? themeHelper.get('background') : themeHelper.get('backgroundLogin'))};
    -webkit-font-smoothing: antialiased;
    &.fixed-scroll{
      overflow:hidden;
    }
    
    & .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
        font-weight: normal;
        font-family: ${themeHelper.get('font.secondary')};
      }

    & .react-datepicker__header {
        background-color: ${themeHelper.get('background')};
      }


    & .react-datepicker__day--selected {
      background-color: ${themeHelper.get('secondary')} !important;
    }

    & .react-datepicker__day--keyboard-selected {
        background-color: white;
        color: black;
        font-weight: normal;
      }

    & .react-datepicker__day--keyboard-selected:hover {
      background-color: #f0f0f0;
    }
  }


  @media screen and (max-width: 768px) {
    table{
      display: inline-table !important;
    }
    .logo{
      max-width: 90px !important;
      margin-bottom: 0px !important;
    }
    .list-button{
      display: block !important;
      width: 15px !important;
    }
    .close-menu{
      display: block !important;  
    }
    .sidebar{
      display: none;
      z-index: 100 !important;
      &.active{
        display: block;
      }
    }
    .main-content {
      padding-left: 0px !important;
    }
  }
`;

export default GlobalStyles;

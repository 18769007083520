import { Spinner } from '@allfundsbank/react-ui-kit-portal';
import { memo } from 'react';
import styled from 'styled-components';
import logo from 'src/static/Login/ccla-logo.svg';

const MainContainer = styled.div`
  min-width: 100%;
  min-height: 500px;
  height: 100%;
`;
const Container = styled.div`
  margin: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 30px;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const StyledLogo = styled.img`
  max-width: 200px;
  margin: 24px;
`;
const StyledSpinner = styled(Spinner)`
  margin: 0 30px;
  & .path {
    opacity: 0.7;
    stroke: #5d6164;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
`;

const LoadingSpinnerCCLA = () => (
  <MainContainer>
    <Container>
      <StyledLogo src={logo} alt="logo" />
      <StyledSpinner size={50} />
    </Container>
  </MainContainer>
);

export default memo(LoadingSpinnerCCLA);

const PORTAL_INVITATION = Object.freeze({
    PORTAL_ERROR_INVITATION_01: 'PORTAL_ERROR_INVITATION_01',
    PORTAL_ERROR_INVITATION_02: 'PORTAL_ERROR_INVITATION_02',
    PORTAL_ERROR_INVITATION_03: 'PORTAL_ERROR_INVITATION_03',
    PORTAL_ERROR_INVITATION_04: 'PORTAL_ERROR_INVITATION_04',
    PORTAL_ERROR_INVITATION_05: 'PORTAL_ERROR_INVITATION_05',
    PORTAL_ERROR_INVITATION_06: 'PORTAL_ERROR_INVITATION_06',
    PORTAL_ERROR_INVITATION_07: 'PORTAL_ERROR_INVITATION_07',
    PORTAL_ERROR_INVITATION_08: 'PORTAL_ERROR_INVITATION_08',
    PORTAL_ERROR_INVITATION_09: 'PORTAL_ERROR_INVITATION_09',
    PORTAL_ERROR_INVITATION_10: 'PORTAL_ERROR_INVITATION_10',
    PORTAL_ERROR_INVITATION_11: 'PORTAL_ERROR_INVITATION_11',
    PORTAL_ERROR_INVITATION_12: 'PORTAL_ERROR_INVITATION_12',
    PORTAL_ERROR_INVITATION_13: 'PORTAL_ERROR_INVITATION_13',
});

const ERRORS = { PORTAL_INVITATION };

export default ERRORS;

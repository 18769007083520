import { Spinner } from '@allfundsbank/react-ui-kit-portal';
import styled from 'styled-components';

const StyledSpinner = styled(Spinner)`
display: ${({ isVisible }) => (isVisible ? 'default' : 'none' )};
  & .path {
    opacity: 0.7;
    stroke: ${({ color }) => (color || '#5d6164')};;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
`;
export default StyledSpinner;

import { useEffect, useState } from 'react';

function useMobileDetection(size = 768) {
  const [width, setWidth] = useState(window.innerWidth);
  const condition = width <= size;
  const media = {
    isIpad: (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || (navigator.platform === 'iPad'),
    isPhone: width <= 575 && width > 360,
    isTablet: width <= 768 && width > 575,
    isMobile: width <= 768 || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || (navigator.platform === 'iPad'),
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return [condition, width, media];
}

export default useMobileDetection;

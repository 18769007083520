/* eslint-disable no-undef */
import { PureComponent } from 'react';
import WebFont from 'webfontloader';
import { FONTS } from 'src/enums';

const statuses = {
  INACTIVE: 'inactive',
  ACTIVE: 'active',
  LOADING: 'loading',
};

const noop = () => {};

class WebfontLoader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { status: null };
  }

  componentDidMount() {
    this.loadFonts();
  }

  componentDidUpdate(prevProps, prevState) {
    const { onStatus } = this.props;
    // eslint-disable-next-line react/prop-types
    const { status } = this.state;

    if (prevState.status !== status) {
      onStatus(status);
    }
  }

  handleLoading = () => {
    this.setState({ status: statuses.LOADING });
  };

  handleActive = () => {
    this.setState({ status: statuses.ACTIVE });
  };

  handleInactive = () => {
    this.setState({ status: statuses.INACTIVE });
  };

  loadFonts = () => {
    WebFont.load({
      ...FONTS,
      LOADING: this.handleLoading,
      ACTIVE: this.handleActive,
      INACTIVE: this.handleInactive,
    });
  };

  render() {
    const { children } = this.props;
    return children || null;
  }
}

WebfontLoader.defaultProps = { onStatus: noop };

export default WebfontLoader;

import ADDRESS from 'src/enums/address';
import ANALYSIS from 'src/enums/analysis';
import FONTS from 'src/enums/fonts';
import API_ROUTES from 'src/enums/api-routes';
import LAYOUT from 'src/enums/layout';
import ERRORS from 'src/enums/errors';

const TKN_KEY = '_wtkoegj';
const RESTRICTED_KEY = '_dgeaagegrjj';
const ORG_KEY = '_oqasdfblu';

const XSYS_KEY = '_xsystkoegj';
const SESSION_EXPIRED = 'CCLA_Idle_oHLSVmLOAK';

const MIME_TYPES = {
  pdf: 'application/pdf',
  txt: 'text/plain',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  xlt: 'application/vnd.ms-excel.template',
  xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
  xlam: 'application/vnd.ms-excel.addin.macroEnabled.12',
  csv: 'text/csv',
  doc: 'application/msword',
  dot: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  docm: 'application/vnd.ms-word.document.macroEnabled.12',
  dotm: 'application/vnd.ms-word.template.macroEnabled.12',
  ppt: 'application/vnd.ms-powerpoint',
  pot: 'application/vnd.ms-powerpoint',
  pps: 'application/vnd.ms-powerpoint',
  ppa: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
  ppsx: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  ppam: 'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  pptm: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  potm: 'application/vnd.ms-powerpoint.template.macroEnabled.12',
  ppsm: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  mdb: 'application/vnd.ms-access',
};

export { TKN_KEY, RESTRICTED_KEY, ORG_KEY, ADDRESS, SESSION_EXPIRED, XSYS_KEY, ANALYSIS, FONTS, API_ROUTES, LAYOUT, ERRORS, MIME_TYPES };

const DASHBOARD_CARDS = Object.freeze({
  PERFORMANCE: 'performance-card',
  POSITIONS: 'positions-card',
  TRANSACTIONS: 'transaction-card',
  DISTRIBUTIONS: 'distributions-card',
  REINVESTMENT_TRANSFERS: 'reinvestment-transfers'
  // RISKS: 'risks-card',
});

const DISTRIBUTIONS_LAYOUT = Object.freeze({
  categories: 'categories',
  product_types: 'product_types',
  countries: 'countries',
  sectors: 'sectors',
  currencies: 'currencies',
  top_ten: 'top_ten',
});

const DEFAULT_DISTRIBUTIONS_LAYOUT = ['categories', 'product_types', 'countries', 'sectors', 'currencies', 'top_ten'];

const DEFAULT_MINH_CARDS = 6;
const DEFAULT_MAXH_CARDS = 24;

const DEFAULT_DASHBOARD_LAYOUT = [
  {
    x: 0,
    y: 0,
    w: 8,
    h: 11,
    i: DASHBOARD_CARDS.PERFORMANCE,
    minH: DEFAULT_MINH_CARDS,
    maxH: DEFAULT_MAXH_CARDS,
  },
  // {
  //   x: 0,
  //   y: 0,
  //   w: 8,
  //   h: 11,
  //   i: DASHBOARD_CARDS.DISTRIBUTIONS,
  //   maxH: DEFAULT_MAXH_CARDS,
  // },
  {
    x: 0,
    y: 0,
    w: 8,
    h: 11,
    i: DASHBOARD_CARDS.TRANSACTIONS,
    minH: DEFAULT_MINH_CARDS,
    maxH: DEFAULT_MAXH_CARDS,
  },
  {
    x: 0,
    y: 0,
    w: 8,
    h: 11,
    i: DASHBOARD_CARDS.POSITIONS,
    minH: DEFAULT_MINH_CARDS,
    maxH: DEFAULT_MAXH_CARDS,
  },
  {
    x: 0,
    y: 0,
    w: 8,
    h: 12,
    i: DASHBOARD_CARDS.DISTRIBUTIONS,
    minH: DEFAULT_MINH_CARDS,
    maxH: DEFAULT_MAXH_CARDS,
  },

  {
    x: 0,
    y: 0,
    w: 8,
    h: 8,
    i: 'portfolioCardPerformance',
    minH: DEFAULT_MINH_CARDS,
    maxH: DEFAULT_MAXH_CARDS,
  },
];

const LAYOUT = {
  DASHBOARD_CARDS,
  DEFAULT_DASHBOARD_LAYOUT,
  DISTRIBUTIONS_LAYOUT,
  DEFAULT_DISTRIBUTIONS_LAYOUT,
  DEFAULT_MINH_CARDS,
  DEFAULT_MAXH_CARDS,
};

export default LAYOUT;

import { Button, Col, Label, Modal, Row, Textfield, TextArea } from '@allfundsbank/react-ui-kit-portal';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FloatingMailIcon } from '../Templates';
import { themeHelper } from 'src/utils';

const StyledModal = styled(Modal)`
  width: 15%;
  height: 360px;
  position: fixed;
  bottom: -150px;
  right: -182px;
  top: unset;
  left: unset;
  &.MailModal{
    padding-right: 0px !important;
  } 
  & > i {
    display: none;
  }

  @media only screen and (min-width: 360px) and (max-width: 575px) {
    width: 100%;
    height: 633px;
    max-width: 100%;
    position: absolute;
    min-width: 90%;
    max-height: 100%;
    right: 50%;
    bottom: unset;
    left: 50%;
    bottom: 0px;
    top: 50%;
    & > i {
      display: flex;
    }
  }
`;

const TextFieldWrapper = styled.div`
  height: 120px;
  & > div {
    height: 120px;
  }
  textarea{
    height: 90px;
  }
  @media only screen and (min-width: 360px) and (max-width: 575px) {
    height: 400px;
    textarea{
      height: 370px;
    }
    & > div {
      height: 400px;
    }
  }
`;
const ModalDiv = styled.div`
  @media only screen and (min-width: 360px) and (max-width: 575px) {
    .backdrop{
      padding-right: 30px !important;
      height: 100% !important;
    }
  }
`;

const FloatingSendMail = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const bottomPosition = 24;
  const myRef = useRef();

  const handlerSubmmit = (e) => {
    e.preventDefault();
    dispatch.dashboard.sendContactData(formData);
    setShow(false);
  };

  const handlerFormChanged = (e) => {
    const updatedData = { ...formData };
    updatedData[e.target.name] = e.target.value;
    setFormData(updatedData);
  };
  useEffect(() => {
    if (show) {
      myRef.current.scrollIntoView();
      window.scrollBy(0, -50);
      // console.log('scroll', myRef.current.offsetTop, myRef.current);
      // window.scrollTo(0, (myRef.current.offsetTop + 400));
    }
  }, [show]);

  return (
    <>
      <FloatingMailIcon
        type="mail"
        onClick={() => { setShow(true); }}
        bottomPosition={bottomPosition}
      />
      <ModalDiv>
        <StyledModal className="MailModal" bottomPosition={bottomPosition} show={show} onClose={() => setShow(false)} transition="none" backgroundFadeIn={0.6} animationDelay={0.3}>
          <div ref={myRef}>
            <form onSubmit={(e) => handlerSubmmit(e)} autoComplete="off">
              <Row>
                <Col xs={12} margintop="sm">
                  <Label fontSize={24} textalign="center" fontFamily={themeHelper.get('font.secondary')} lineHeight={24}>
                    {t('FORM_DATA')}
                  </Label>
                </Col>

              </Row>
              <Row>

                <Col xs={12} margintop="sm">
                  <Textfield placeholder={t('SUBJECT')} type="text" name="subject" onChange={handlerFormChanged} />
                </Col>
                <Col xs={12} margintop="sm">
                  <TextFieldWrapper className="TextFieldWrapper">
                    <TextArea placeholder={t('MESSAGE')} name="body" onChange={handlerFormChanged} />
                  </TextFieldWrapper>
                </Col>
                <Col xs={12} margintop="lg" textalign="center" justifycontent="center">
                  <Button type="submit" maxWidth="100%">
                    {t('SEND')}
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </StyledModal>
      </ModalDiv>
    </>
  );
};

export default FloatingSendMail;

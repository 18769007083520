import { getTodayAsString, getAsString, getYear, getToday, subtractYears } from '@allfundsbank/np-date';
import { ANALYSIS } from 'src/enums';

const DEFAULT_LIMIT = 10;

const formatInstrumentOptions = (instruments) => {
  if (!instruments?.length) return [];

  return instruments.map(({ id, name }) => ({ key: id, label: name }));
};

const formatCurrenciesOptions = (currencies) => {
  if (!currencies?.length) return [];

  return currencies.map(({ code }) => ({ key: code, label: code }));
};

const formatAssetClassOptions = (assetClasses) => {
  if (!assetClasses?.length) return [];

  return assetClasses.map(({ name, code, parentCode }) => ({
    key: code,
    label: name,
    parentCode,
  }));
};

const getRatiosDates = (period) => {
  const today = getToday();
  const lastYear = getYear() - 1;
  const currentMonth = new Date(today).getMonth();
  const currentDate = new Date(today).getDate();
  let dateFrom;

  switch (period) {
    case ANALYSIS.PERFORMANCE_TIME.YTD:
      dateFrom = getAsString(new Date(lastYear, 11, 31));
      break;
    case ANALYSIS.PERFORMANCE_TIME.Y1:
      dateFrom = getAsString(new Date(lastYear, currentMonth, currentDate));
      break;

    default:
      break;
  }

  return [dateFrom, getTodayAsString()];
};

const getRatiosBody = (instruments, currency, period) => {
  const dates = getRatiosDates(period);

  return {
    currency,
    items: instruments.map(({ id }) => ([{ instrumentId: id, weight: 1 }])),
    dateFrom: dates[0],
    dateTo: dates[1],
  };
};

const setPerformanceRatios = (instruments, ratios, period) => {
  const copyInstruments = [...instruments];

  for (let i = 0; i < instruments.length; i += 1) {
    copyInstruments[i][`performance${period.toUpperCase()}`] = ratios[i].performance;
  }

  return copyInstruments;
};

const getRiskOptions = (t) => [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
  { key: '6', label: '6' },
  { key: '7', label: '7' },
  { key: null, label: t('SCREENER_RISK_NOT_AVAILABLE') },
];

const getPeriodPerformanceArray = (t, initialDate) => {
  const { date } = initialDate || {};
  if (!date) return [];

  const today = getToday();
  const lastYear = getYear() - 1;
  const currentMonth = new Date(today).getMonth();
  const currentDate = new Date(today).getDate();

  const ytd = new Date(lastYear, 11, 31);
  const y1 = new Date(lastYear, currentMonth, currentDate);
  const y3 = new Date(lastYear - 2, currentMonth, currentDate);
  const y5 = new Date(lastYear - 4, currentMonth, currentDate);

  return [{
    label: t('PERIOD_YTD'),
    key: ANALYSIS.PERFORMANCE_TIME.YTD,
    disabled: ytd.getTime() < new Date(date).getTime(),
  }, {
    label: t('PERIOD_Y1'),
    key: ANALYSIS.PERFORMANCE_TIME.Y1,
    disabled: y1.getTime() < new Date(date).getTime(),
  }, {
    label: t('PERIOD_Y3'),
    key: ANALYSIS.PERFORMANCE_TIME.Y3,
    disabled: y3.getTime() < new Date(date).getTime(),
  }, {
    label: t('PERIOD_Y5'),
    key: ANALYSIS.PERFORMANCE_TIME.Y5,
    disabled: y5.getTime() < new Date(date).getTime(),
  }];
};

const getPeriodDates = (period, initialDate) => {
  // eslint-disable-next-line no-param-reassign
  period = period || ANALYSIS.PERFORMANCE_TIME.INCEPTION;

  const today = getToday();
  const lastYear = getYear() - 1;
  const currentMonth = new Date(today).getMonth();
  const currentDate = new Date(today).getDate();
  let dateFrom;

  const inceptionAsString = initialDate?.dateAsString || getTodayAsString();
  const inceptionDate = initialDate?.date ? new Date(initialDate?.date) : new Date();
  let ytd;
  let y1;
  let y3;
  let y5;

  switch (period) {
    case ANALYSIS.PERFORMANCE_TIME.YTD:
      ytd = new Date(lastYear, 11, 31);
      ytd = ytd.getTime() < inceptionDate.getTime() ? inceptionDate : ytd;
      dateFrom = getAsString(ytd);
      break;
    case ANALYSIS.PERFORMANCE_TIME.Y1:
      y1 = new Date(lastYear, currentMonth, currentDate);
      y1 = y1.getTime() < inceptionDate.getTime() ? inceptionDate : y1;
      dateFrom = getAsString(y1);
      break;
    case ANALYSIS.PERFORMANCE_TIME.Y3:
      y3 = new Date(lastYear - 2, currentMonth, currentDate);
      y3 = y3.getTime() < inceptionDate.getTime() ? inceptionDate : y3;
      dateFrom = getAsString(y3);
      break;
    case ANALYSIS.PERFORMANCE_TIME.Y5:
      y5 = new Date(lastYear - 4, currentMonth, currentDate);
      y5 = y5.getTime() < inceptionDate.getTime() ? inceptionDate : y5;
      dateFrom = getAsString(y5);
      break;
    case ANALYSIS.PERFORMANCE_TIME.INCEPTION:
      dateFrom = inceptionAsString;
      break;

    default:
      break;
  }

  return [dateFrom, getTodayAsString()];
};

const hasInception5Years = (date) => {
  const minDate = new Date(date);
  const threeYears = subtractYears(5, getToday());
  const y5 = minDate.getTime() <= threeYears.getTime();

  return y5;
};

const getUpdatListQuery = (params, visibilityLimitedToFod) => ({
  limit: DEFAULT_LIMIT,
  skip: params.page ? (params.page - 1) * DEFAULT_LIMIT : 0,
  q: params.q ? params.q : undefined,
  'instrumentIds[]': params.instrumentId && !params.q ? [params.instrumentId] : undefined,
  'currencies[]': params.currencies ? params.currencies.split(',').filter((i) => i) : undefined,
  'riskIds[]': params.riskIds ? params.riskIds.split(',').filter((i) => i) : undefined,
  'assetClasses[]': params.assetClass ? params.assetClass.split(',').filter((i) => i) : undefined,
  'subAssetClasses[]': params.subAssetClass ? params.subAssetClass.split(',').filter((i) => i) : undefined,
  operativeOnly: visibilityLimitedToFod,
});

const SCREENER = {
  formatInstrumentOptions,
  formatCurrenciesOptions,
  formatAssetClassOptions,
  hasInception5Years,
  getPeriodDates,
  getRatiosBody,
  getRiskOptions,
  getPeriodPerformanceArray,
  getUpdatListQuery,
  setPerformanceRatios,
};

export default SCREENER;

import { createModel } from '@rematch/core';
import jwtDecode from 'jwt-decode';
import { addDays } from '@allfundsbank/np-date';

import { saveToStorage, loadFromStorage, HttpInstance, displaySuccessMsg, displayErrorMsg } from 'src/utils';
import { API_ROUTES, TKN_KEY, RESTRICTED_KEY } from 'src/enums';
import { dropStorage } from 'src/utils/local-storage';

const http = new HttpInstance();
const httpNoLogout = new HttpInstance({ noLogout: true });
const DEFAULT_CURRENCY = 'GBP';
const parseToken = (tkn) => (tkn ? jwtDecode(tkn) : null);

async function preCommonLogin(endpoint, postBody, dispatch) {
  try {
    const { verificationCode, isFullAuth, user } = await http.post(endpoint, postBody, { forceCrash: true });

    if (isFullAuth) {
      const { token, ...client } = user;
      saveToStorage(TKN_KEY, token, addDays(1, new Date()));
      http.instance.interceptors.request.use(
        (config) => {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `bearer ${token}`;
          return config;
        },
        (error) => {
          Promise.reject(error);
        },
      );
      const personalInfo = await http.get(`${API_ROUTES.PERSONS_DETAIL}/${client?.personId}`);
      await dispatch.auth.SET_LOGIN_USER({ ...client, personalInfo });
      await dispatch.auth.SET_PROP({ showTermsAndConditions: !client.signedAcceptance });
    } else {
      await dispatch.auth.SET_SHOW_TWIGO_MODAL({ verificationCode });
    }
  } catch (err) {
    dispatch.auth.ERROR({ error: { changePassword: true, message: err?.message } });
  }
}

async function doRefreshToken(endpoint, postBody, dispatch) {
  try {
    const result = await http.post(endpoint, postBody);
    await dispatch.auth.SET_PROP({ verificationCode: result?.verificationCode });
  } catch (error) {
    dispatch.auth.ERROR();
  }
}

async function commonLogin(endpoint, postBody, dispatch) {
  const { token, restricted, ...client } = await http.post(endpoint, postBody);

    saveToStorage(RESTRICTED_KEY, restricted || false, addDays(1, new Date()), restricted);
    saveToStorage(TKN_KEY, token, addDays(1, new Date()), restricted);

  http.instance.interceptors.request.use(
    (config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `bearer ${token}`;
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  const personalInfo = await http.get(`${API_ROUTES.PERSONS_DETAIL}/${client?.personId}`);

  await dispatch.auth.SET_LOGIN_USER({ ...client, personalInfo });
  await dispatch.auth.SET_PROP({ showTermsAndConditions: !client.signedAcceptance });
}

const auth = createModel()({
  name: 'auth',
  state: {
    isAuthenticated: !!loadFromStorage(TKN_KEY),
    showInputTokenPage: false,
    showTermsAndConditions: false,
    client: undefined,
    entityAnalysis: { currency: DEFAULT_CURRENCY },
    lang: undefined,
    error: undefined,
    verificationCode: '',
    isSessionExpired: false,
  },
  reducers: {
    SET_SHOW_TWIGO_MODAL(state, payload = {}) {
      const { verificationCode, showTermsAndConditions } = payload;
      return {
        ...state,
        verificationCode,
        showTermsAndConditions,
        error: undefined,
        showInputTokenPage: true,
      };
    },
    SET_PROP(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    SET_LOGIN_USER(state, payload) {
      return {
        ...state,
        isAuthenticated: true,
        showTermsAndConditions: false,
        showInputTokenPage: false,
        client: payload,
        error: undefined,
      };
    },
    SET_CLIENT(state, payload) {
      return {
        ...state,
        client: payload,
      };
    },
    SET_LANG(state, payload) {
      return {
        ...state,
        lang: payload,
      };
    },
    SET_ENTITY_ANALISYS(state, payload) {
      return {
        ...state,
        entityAnalysis: payload,
      };
    },
    ERROR(state, payload) {
      return {
        ...state,
        isAuthenticated: false,
        client: undefined,
        error: true,
        ...payload,
      };
    },
    ERROR_MOCK(state, payload) {
      return {
        ...state,
        error: true,
        ...payload,
      };
    },
  },
  effects: (dispatch) => ({
    async preLoginUser(payload) {
      try {
        await preCommonLogin(API_ROUTES.PRE_AUTH, payload, dispatch);
      } catch (err) {
        dispatch.auth.ERROR();
      }
    },
    async registerUser({data}) {
      // eslint-disable-next-line no-console
      const params = {
        name: data.name,
        organisation: data.organisation,
        phone: data.phone,
        email: data.email,
      }
      try {
        await preCommonLogin(API_ROUTES.SIGN_UP, params, dispatch);
      } catch (err) {
        displayErrorMsg('an error ocurred', 5000);
      }
    },
    async sendRefreshToken(payload, state) {
      try {
        let data = payload;
        if (payload.oldPassword) {
          data = {
            username: state.auth?.client?.username,
            password: payload.oldPassword,
            requestType: 'change-password',
          };
        }
        if (payload.token) {
          data = {
            password: payload.password,
            token: payload.token,
            requestType: 'reset-password',
          };
        }
        await doRefreshToken(API_ROUTES.REFRESH_TOKEN, data, dispatch);
        displaySuccessMsg('Security code sent successfully', 5000);
      } catch (err) {
        dispatch.auth.ERROR();
      }
    },
    async preResetPasswordHandler(payload) {
      const { data, token } = payload;
      const { confirmPassword, password, oldPassword } = data;
      if (confirmPassword !== password) {
        dispatch.auth.ERROR({ error: { differentsPass: true } });
        return;
      }

      const passwordRules = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*_-])(?=.{8,})';
      if (!password.match(passwordRules)) {
        dispatch.auth.ERROR({ error: { policy: true } });
        return;
      }

      try {
        if (token) { // no loged neccesary
          await preCommonLogin(API_ROUTES.PUBLIC_AUTH_REQUEST_PASSWORD_2FA, { token, newPassword: password }, dispatch);
        } else { // required auth
          const response = await httpNoLogout.post(API_ROUTES.AUTH_REQUEST_CHANGE_PASSWORD, { password: oldPassword, newPassword: password }, { forceCrash: true });
          await dispatch.auth.SET_SHOW_TWIGO_MODAL({ verificationCode: response?.verificationCode });
        }
      } catch (err) {
        if (err?.message) {
          const errorMsg = err?.statusCode === 401 ? 'Invalid password' : err?.message;
          dispatch.auth.ERROR_MOCK({ error: { changePassword: true, message: errorMsg } });
        }
      }
    },
    async loginUser(payload) {
      try {
        await commonLogin(API_ROUTES.AUTH, payload, dispatch);
      } catch (err) {
        await dispatch.auth.ERROR();
      }
    },
    async simulateLogin(payload) {
      try {
        dropStorage(true);
        await commonLogin(API_ROUTES.SIMULATE_AUTH, payload, dispatch);
        window.location.href = '/';
      } catch (err) {
        dispatch.auth.ERROR();
        window.location.href = '/';
      }
    },
    async sendEmail(payload) {
      const { username } = payload;
      await http.post(API_ROUTES.PUBLIC_AUTH_REQUEST_PASSWORD, { username });
      displaySuccessMsg("An email has been sent to this user's email address", 5000);
    },
    async changePassword(payload) {
      try {
        await http.put(API_ROUTES.AUTH_CHANGE_PASSWORD, payload, { forceCrash: true });
        await dispatch.auth.SET_PROP({ showInputTokenPage: false });
        displaySuccessMsg('Your password has changed successfully', 5000);
        return true;
      } catch (err) {
        dispatch.auth.ERROR_MOCK();
        return false;
      }
    },
    async generateNewPass(payload) {
      try {
        await http.post(API_ROUTES.PUBLIC_AUTH_REQUEST_RESET_PASSWORD, payload);
        displaySuccessMsg('Your password has changed successfully', 5000);
      } catch (err) {
        dispatch.auth.ERROR();
      }
    },
    async refreshToken() {
      const parsedToken = parseToken(loadFromStorage(TKN_KEY));
      const personalInfo = await http.get(`${API_ROUTES.PERSONS_DETAIL}/${parsedToken?.personId}`);
      dispatch.auth.SET_CLIENT({ ...parsedToken, personalInfo });
    },
    async acceptTermsAndConditions(payload, state) {
      const client = state?.auth?.client;
      if (!client?.personId) return;
      await http.put(`${API_ROUTES.USERS}/${client?.personId}/acceptance`);
      dispatch.auth.SET_PROP({ showTermsAndConditions: false });
      dispatch.auth.SET_CLIENT({ ...client, signedAcceptance: true });
    },
    async logout(callback) {
      await http.post(API_ROUTES.LOGOUT);
      dropStorage(loadFromStorage(RESTRICTED_KEY));

      if (callback) callback();
    },
    changeLang(payload) {
      dispatch.auth.SET_LANG(payload);
    },
  }),
});

export default auth;

const SIZES = {
    xxxs: '2px',
    xxs: '4px',
    xs: '6px',
    sm: '12px',
    md: '18px',
    lg: '24px',
    xl: '36px',
    xxl: '48px',
    xxxl: '60px',
    em1: '1em',
    em2: '2em',
    em3: '3em',
    em4: '4em',
    em5: '5em',
    em6: '6em',
    em7: '7em',
    em8: '8em',
    em9: '9em',
    em10: '10em',
};

export default SIZES;

import { lazy } from 'react';
import RoutesEnum from 'src/router/enums';

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const lazyMinLoadTime = (factory, minLoadTimeMs = 200) => lazy(() => Promise.all([factory(), new Promise((resolve) => setTimeout(resolve, minLoadTimeMs))]).then(([moduleExports]) => moduleExports));

const LazyDashboard = lazyMinLoadTime(() => retry(() => import('../pages/Dashboard/DashboardPage')));
const LazyDocumentation = lazyMinLoadTime(() => retry(() => import('../pages/Documentation/DocumentationPage')));
const LazyDocumentationAccountDetail = lazyMinLoadTime(() => retry(() => import('../pages/Documentation/DocumentationAccountDetail')));
const LazyAccounts = lazyMinLoadTime(() => retry(() => import('../pages/Accounts/AccountsPage')));
const LazyFaq = lazyMinLoadTime(() => retry(() => import('../pages/Faq/FaqPage')));
const LazyAdminUsers = lazyMinLoadTime(() => retry(() => import('../pages/AdminUsers/AdminUsersPage')));
const LazyPersonDetails = lazyMinLoadTime(() => retry(() => import('../pages/AdminUsers/PersonDetails')));
const LazyProfile = lazyMinLoadTime(() => retry(() => import('../pages/Profile/ProfilePage')));
const LazyProfileDetails = lazyMinLoadTime(() => retry(() => import('../pages/Profile/ProfileChagePassword')));
const LazyInvitation = lazyMinLoadTime(() => retry(() => import('../pages/Invitation/InvitationPageLoader')));
const LazyForgotPassword = lazyMinLoadTime(() => retry(() => import('../pages/Login/ForgotPasswordPage')));
const ResetPassword = lazyMinLoadTime(() => retry(() => import('../pages/Login/ResetPassword')));
const LazySignUp = lazyMinLoadTime(() => retry(() => import('../pages/Login/SignUpPage')));

/**
 * TODO: Move to Object.freeze pathId identifiers of segment
 */

const RoutesSchema = [
  {
    path: RoutesEnum.main.view,
    pathId: 'MAIN',
    page: LazyDashboard,
    isExact: true,
  },
  {
    path: RoutesEnum.documentation.view,
    pathId: 'DOCUMENTATION',
    page: LazyDocumentation,
    isExact: true,
  },
  {
    path: RoutesEnum.documentation.detail,
    pathId: 'DOCUMENTATION_ACCOUNT_DETAIL',
    page: LazyDocumentationAccountDetail,
    isExact: true,
  },
  {
    path: RoutesEnum.profile.view,
    pathId: 'PROFILE',
    page: LazyProfile,
    isExact: true,
  },
  {
    path: RoutesEnum.profile.detail,
    pathId: 'PROFILE_DETAILS',
    page: LazyProfileDetails,
    isExact: true,
  },
  {
    path: RoutesEnum.accounts.view,
    pathId: 'ACCOUNTS',
    page: LazyAccounts,
    isExact: true,
  },
  {
    path: RoutesEnum.faq.view,
    pathId: 'FAQ',
    page: LazyFaq,
    isExact: true,
  },
  {
    path: RoutesEnum.adminUsers.view,
    pathId: 'ADMIN_USERS',
    page: LazyAdminUsers,
    isExact: true,
  },
  {
    path: RoutesEnum.adminUsers.detail,
    pathId: 'PERSON_DETAILS',
    page: LazyPersonDetails,
    isExact: true,
  },
  {
    path: RoutesEnum.invitation.view,
    pathId: 'INVITATION',
    page: LazyInvitation,
    isExact: true,
  },
  {
    path: RoutesEnum.forgotPassword.view,
    pathId: 'FORGOTPASSWORD',
    page: LazyForgotPassword,
    isExact: true,
  },
  {
    path: RoutesEnum.resetPassword.view,
    pathId: 'RESETPASSWORD',
    page: ResetPassword,
    isExact: true,
  },
  {
    path: RoutesEnum.signUp.view,
    pathId: 'SIGNUP',
    page: LazySignUp,
    isExact: true,
  }
];

export default RoutesSchema;

/* eslint-disable import/no-anonymous-default-export */

const ID_CARD = Object.freeze({
  NIF: 'NIF',
  NIE: 'NIE',
  TAR: 'TAR',
  OPA: 'OPA',
  JNR: 'JNR',
  MEN: 'MEN', // UNDERAGE DOCUMENT
  PAS: 'PAS', // PASSPORT
  OTH: 'OTH', // OTHER
});

const ADDRESS_TYPES = Object.freeze({
  AL: 'AL',
  AP: 'AP',
  AV: 'AV',
  BL: 'BL',
  BO: 'BO',
  CH: 'CH',
  CL: 'CL',
  CM: 'CM',
  CO: 'CO',
  CR: 'CR',
  CS: 'CS',
  CT: 'CT',
  ED: 'ED',
  GL: 'GL',
  GR: 'GR',
  LG: 'LG',
  MC: 'MC',
  MN: 'MN',
  PG: 'PG',
  PJ: 'PJ',
  PQ: 'PQ',
  PR: 'PR',
  PS: 'PS',
  PZ: 'PZ',
  RB: 'RB',
  TR: 'TR',
  UR: 'UR',
});

export default {
  ID_CARD,
  ADDRESS_TYPES,
};

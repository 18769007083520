const RoutesEnum = {
  main: { view: '/' },
  documentation: { view: '/documentation', detail: '/documentation/:id' },
  accounts: { view: '/accounts' },
  faq: { view: '/faq' },
  adminUsers: { view: '/admin', detail: '/person/:id' },
  profile: { view: '/profile', detail: '/profile/change-password' },
  invitation: { view: '/invitation' },
  forgotPassword: { view: '/forgot-password' },
  resetPassword: { view: '/reset-password' },
  screener: { view: '/screener' },
  signUp: { view: '/sign-up'},
};

export default RoutesEnum;

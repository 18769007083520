import { getI18n } from 'react-i18next';

const DEFAULT_LANG = 'en';
const KEY_CHART = '-';

const getLang = () => {
  let lang;
  const { language } = getI18n();
  lang = language;
  if (lang && lang.includes(KEY_CHART)) {
    // eslint-disable-next-line prefer-destructuring
    lang = lang.split(KEY_CHART)[0];
  }

  return (DEFAULT_LANG).toLowerCase();
};

export default getLang;

import { createModel } from '@rematch/core';
import { saveAs } from 'file-saver';

import { DASHBOARD } from 'src/helpers';
import { HttpInstance, getLang } from 'src/utils';
import { API_ROUTES } from 'src/enums';

const http = new HttpInstance();
const { getPeriodDates } = DASHBOARD;

const DEFAULT_ANALYSIS_TYPE = 'transactions';
const WIP_STATUS = 'WIP';
const DATA_BLOB_PDF = 'data:application/pdf;base64,';

const pdf = createModel()({
  name: 'pdf',
  state: { downloadInCourse: undefined },
  reducers: {
    SET_PROP(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: (dispatch) => ({
    async downloadPdfDashboard(payload, state) {
      const { currency } = state.auth.entityAnalysis;
      const { downloadInCourse } = state.pdf;
      const { positionsGroupBy } = state.theme;
      if (downloadInCourse) return;

      const lang = getLang();
      const { contractIds } = payload;
      const { period, initialDate } = state.dashboard;
      const [dateFrom, dateTo] = getPeriodDates(period, initialDate);

      const { selectedBenchmark } = state.benchmark;

      const params = {
        analysisType: DEFAULT_ANALYSIS_TYPE,
        conciliate: true,
        contractIds: Array.isArray(contractIds) ? contractIds : [contractIds],
        currency,
        customBenchmarkId: selectedBenchmark?.key,
        dateFrom,
        dateTo,
        lang,
        positionsGroupBy,
      };

      const pdfItem = await http.post(API_ROUTES.PDF_BACKGROUND, params);
      dispatch.pdf.SET_PROP({ downloadInCourse: pdfItem });
    },
    async checkCurrentDownloadReady(_, state) {
      const { downloadInCourse } = state.pdf;
      const pdfParams = { 'ids[]': [downloadInCourse.id] };
      const [pdfItem] = await http.get(API_ROUTES.PDF_SEARCH, pdfParams);

      if (pdfItem.status !== WIP_STATUS) {
        dispatch.pdf.SET_PROP({ downloadInCourse: undefined });
        const response = await http.get(`${API_ROUTES.PDF_CONTRACTS}/${pdfItem.id}/pdf`);
        const b64 = http.dataURItoBlob({ data: `${DATA_BLOB_PDF}${response}` });
        saveAs(b64, `${pdfItem.id}.pdf`);
      }
    },
  }),
});

export default pdf;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAuth from 'src/hooks/useAuth';
import RouterLoader from 'src/loaders/RouterLoader';
import Login from 'src/pages/Login/LoginPage';
import RoutesEnum from 'src/router/enums';

const getClient = ({ auth }) => ({ client: auth?.client, showTermsAndConditions: auth?.showTermsAndConditions });
const getCurrentLocation = ({ router }) => router.location.pathname;

const loginExcludedPaths = [RoutesEnum.invitation.view, RoutesEnum.forgotPassword.view, RoutesEnum.signUp.view, RoutesEnum.resetPassword.view];

const Router = ({ history }) => {
  const isAuthenticated = useAuth();
  const dispatch = useDispatch();
  const query = new URLSearchParams(document.location.search);
  const { client, showTermsAndConditions } = useSelector(getClient);
  const locationPath = useSelector(getCurrentLocation);

  useEffect(() => {
    if (isAuthenticated && !client) {
      dispatch.auth.refreshToken();
    }
  }, []);

  if ((!isAuthenticated && !loginExcludedPaths.includes(locationPath)) || showTermsAndConditions || query.get('session')) return <Login />;

  return <RouterLoader history={history} />;
};

export default Router;

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider as ReduxProvider } from 'react-redux';
import App from 'src/App';
import { store, history } from 'src/store';
import WebfontLoader from 'src/loaders/WebfontLoader';
import 'src/loaders/I18nLoader';

ReactDOM.render(
  <ReduxProvider store={store}>
    <WebfontLoader>
      <App history={history} />
    </WebfontLoader>
  </ReduxProvider>,
  document.getElementById('root')
);

import { init } from '@rematch/core';
import loadingPlugin from '@rematch/loading';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as models from 'src/models';

const history = createBrowserHistory();

const loadingConfig = { type: 'full' };
const reducers = { router: connectRouter(history) };
const middlewares = [routerMiddleware(history)];

const store = init({
  name: 'afb clients',
  models,
  plugins: [loadingPlugin(loadingConfig)],
  redux: { reducers, middlewares },
});

export { store, history };

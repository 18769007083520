import { css } from 'styled-components';
import get from 'lodash.get';

const getThemeValue = (key, customTheme) => {
  if (customTheme) {
    const val = get(customTheme, key);
    return val;
  }

  return css`${({ theme }) => {
    const val = get(theme, key);
    return val;
  }}`;
};

const themeHelper = { get: getThemeValue };

export default themeHelper;

/* eslint-disable */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { stringify, parse } from 'qs';
import { addDays } from '@allfundsbank/np-date';

import { TKN_KEY, XSYS_KEY, RESTRICTED_KEY } from 'src/enums';
import { dropStorage, loadFromStorage, saveToStorage } from 'src/utils/local-storage';

const DATA_BLOB_PDF = 'data:application/pdf;base64,';

function initUrl() {
  return `${window.appConfig.API_URL}`;
}

function blobToBase64(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = (event) => {
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
}

function getSystemToken() {
  if (loadFromStorage(XSYS_KEY)) return loadFromStorage(XSYS_KEY);

  return '';
}

function getAuth() {
  if (loadFromStorage(TKN_KEY)) return `Bearer ${loadFromStorage(TKN_KEY)}`;

  return '';
}

class HttpInstance {
  constructor(props) {
    this.instance = axios.create({
      baseURL: initUrl(),
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        'x-debug-prop-custom': window.appConfig.ENV !== 'production' ? 'request-error' : undefined,
      },
      paramsSerializer: {
        encode: parse,
        serialize: stringify,
      },
    });

    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if ((error && error.response && error.response.status === 401)) {
          if (loadFromStorage(TKN_KEY) && !props?.noLogout) {
            dropStorage(loadFromStorage(RESTRICTED_KEY));
            window.open('/', '_self');
          }
        }
        return Promise.reject(error.response.data);
      },
    );

    this.instance.interceptors.request.use(
      (config) => {
        config.headers.Authorization = getAuth();
        config.headers['x-system-token'] = getSystemToken();
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );
  }

  // eslint-disable-next-line class-methods-use-this
  dataURItoBlob({ data }) {
    let byteString;
    if (data.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(data.split(',')[1]);
    } else {
      byteString = unescape(data.split(',')[1]);
    }
    // separate out the mime component
    const mimeString = data.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  async get(url, params, options = {}) {
    let result;

    try {
      result = await this.instance.get(url, { ...options, params });
    } catch (error) {
      if (options && options.forceCrash) {
        throw error;
      } else {
        // eslint-disable-next-line no-console
        console.error(url, error);
      }
    }

    const systemToken = loadFromStorage(XSYS_KEY);
    if (!systemToken || systemToken !== result?.headers['x-system-token']) {
      saveToStorage(XSYS_KEY, result?.headers['x-system-token'], addDays(1, new Date()), loadFromStorage(RESTRICTED_KEY));
    }

    return result && result.data;
  }

  async post(url, body, options = {}) {
    let result;

    try {
      result = await this.instance.post(url, body, options);
    } catch (error) {
      if (options && options.forceCrash) {
        throw error;
      } else {
        // eslint-disable-next-line no-console
        console.error(url, error);
      }
    }

    return result && result.data;
  }

  async put(url, body, options = {}) {
    let result;

    try {
      result = await this.instance.put(url, body, options);
    } catch (error) {
      if (options && options.forceCrash) {
        throw error;
      } else {
        // eslint-disable-next-line no-console
        console.error(url, error);
      }
    }

    return result && result.data;
  }

  async patch(url, body, options = {}) {
    let result;

    try {
      result = await this.instance.patch(url, body, options);
    } catch (error) {
      if (options && options.forceCrash) {
        throw error;
      } else {
        // eslint-disable-next-line no-console
        console.error(url, error);
      }
    }

    return result && result.data;
  }

  async delete(url, options = {}) {
    let result;

    try {
      result = await this.instance.delete(url, options);
    } catch (error) {
      if (options && options.forceCrash) {
        throw error;
      } else {
        // eslint-disable-next-line no-console
        console.error(url, error);
      }
    }

    return result && result.data;
  }

  async download(url, body, options = {}) {
    let result;

    try {
      result = await this.instance.post(url, body, options);
    } catch (error) {
      if (options && options.forceCrash) {
        throw error;
      } else {
        // eslint-disable-next-line no-console
        console.error(url, error);
      }
    }

    return this.dataURItoBlob(
      options.downloadType !== 'bucket'
        ? result && result.data
        : { data: `${DATA_BLOB_PDF}${result && result.data}` },
    );
  }

  async downloadBlob(url, options = {}) {
    const result = await this.instance.get(url, { ...options, responseType: 'blob' });
    return result.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async blobToB64(file) {
    const result = await axios.get(file, { responseType: 'blob' });
    const data = await blobToBase64(result.data);
    return data;
  }
}

export default HttpInstance;

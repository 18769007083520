import { toast } from 'react-toastify';
import themeHelper from 'src/utils/themeHelper';
import getLang from 'src/utils/getLang';
import HttpInstance from 'src/utils/http';
import numberFormatter from 'src/utils/number';
import graphHelper from 'src/utils/graphHelper';
import { saveToStorage, loadFromStorage, removeFromStorage, dropStorage } from 'src/utils/local-storage';

const displaySuccessMsg = (msg, time = 3000) => toast.success(msg, {
  position: 'top-right',
  autoClose: time,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const displayErrorMsg = (msg, time = 5000) => toast.error(msg, {
  position: 'top-right',
  autoClose: time,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const displayLoadingMsg = (msg) => toast.loading(msg);

const updateSuccessMsg = (id, options) => toast.update(id, {
  render: options.msg,
  type: 'success',
  isLoading: options.isLoading || true,
});

const updateErrorMsg = (id, options) => toast.update(id, {
  render: options.msg,
  type: 'error',
  isLoading: options.isLoading || true,
});

const endLoadingMsg = (id, options) => toast.update(id, {
  render: options.msg,
  type: 'success',
  isLoading: false,
  autoClose: 5000,
});

const capitalizeString = (string = '') => string.charAt(0).toUpperCase() + string.slice(1);

const buildReactFormError = (field, error, customMessages = {}) => {
  let message = '';

  switch (error.type) {
    case 'required':
      message = customMessages.required || `The ${field} is required`;
      break;
    default:
      break;
  }

  return capitalizeString(message);
};

export {
  getLang,
  HttpInstance,
  themeHelper,
  numberFormatter,
  saveToStorage,
  loadFromStorage,
  removeFromStorage,
  dropStorage,
  graphHelper,
  displaySuccessMsg,
  displayErrorMsg,
  displayLoadingMsg,
  updateErrorMsg,
  updateSuccessMsg,
  endLoadingMsg,
  buildReactFormError,
};

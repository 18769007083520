import { getTodayAsString, getAsString, subtractDays,  subtractMonths, getFromString, addYears, getYear, getToday, subtractYears } from '@allfundsbank/np-date';
import { graphHelper, HttpInstance } from 'src/utils';
import { ANALYSIS, API_ROUTES } from 'src/enums';

const http = new HttpInstance();

const PERFORMANCE_SERIE_KEY = 'performance';
const POSITIONS_SORT_KEYS = {
  WEIGHT: 'weight',
  NAME: 'name',
  SHARES: 'quantity',
  VALUE: 'price',
  AMOUNT: 'amount',
  GAIN: 'return',
};
const TRANSACTIONS_SORT_KEYS = {
  TYPE: 'name',
  SHARES: 'quantity',
  AMOUNT: 'amount',
  PRICE: 'price',
  DATE: 'date',
  STATUS: 'name',
};

const getContracts = (page, contracts) => {
  if (!contracts?.length) return [null, null];
  const first = contracts[page];
  const second = contracts[page + 1];

  return [first, second];
};

const getPortfolioIds = (contracts) => {
  let portfolioIds = [];
  for (let i = 0; i < contracts.length; i += 1) {
    const contract = contracts[i];
    const { portfolios } = contract || {};
    const { portfolioIds: ids } = portfolios && portfolios.length ? portfolios[0] : {};
    portfolioIds = portfolioIds.concat(ids);
  }

  return portfolioIds;
};

const parsePerformanceSerie = (serie) => {
  if (!serie || !serie.length) return [[], []];
  const performanceData = [];
  for (let i = 0; i < serie.length; i += 1) {
    const performanceValue = serie[i];
    performanceData.push({
      performance: performanceValue.value,
      date: performanceValue.dateAsString,
    });
  }

  const serieKeys = [PERFORMANCE_SERIE_KEY];
  const [minAmount, maxAmount] = graphHelper.getDomain(performanceData, serieKeys);
  const ticks = graphHelper.calculateTicks([minAmount, maxAmount]);

  return [[minAmount, maxAmount], performanceData, ticks];
};

const getPeriodDates = (period, initialDate) => {
  // eslint-disable-next-line no-param-reassign
  period = period || ANALYSIS.PERFORMANCE_TIME.YTD;
  const today = getToday();
  const lastYear = getYear() - 1;
  const fiveYearsAgo = getYear() - 5;
  const currentMonth = new Date(today).getMonth();
  const currentDate = new Date(today).getDate();
  let dateFrom;

  const inceptionAsString = initialDate?.dateAsString || getTodayAsString();
  const inceptionDate = initialDate?.date ? new Date(initialDate?.date) : null;
  let ytd;
  let y1;
  let y5;
  let m1;

  switch (period) {
    case ANALYSIS.PERFORMANCE_TIME.YTD:
      ytd = new Date(lastYear, 11, 31);
      ytd = inceptionDate || ytd;
      dateFrom = getAsString(ytd);
      break;
    case ANALYSIS.PERFORMANCE_TIME.Y1:
      y1 = new Date(lastYear, currentMonth, currentDate);
      y1 = inceptionDate || y1;
      dateFrom = getAsString(y1);
      break;
    case ANALYSIS.PERFORMANCE_TIME.Y5:
      y5 = new Date(fiveYearsAgo, currentMonth, currentDate);
      y5 = inceptionDate || y5;
      dateFrom = getAsString(y5);
      break;
    case ANALYSIS.PERFORMANCE_TIME.M1:
      m1 = new Date(getYear(), currentMonth - 1, currentDate);
      m1 = inceptionDate || m1;
      dateFrom = getAsString(m1);
      break;
    case ANALYSIS.PERFORMANCE_TIME.INCEPTION:
      dateFrom = inceptionAsString;
      break;

    default:
      break;
  }

  return [dateFrom, getAsString(subtractDays(1, new Date()))];
};

const getPeriodDateHelper = (period) => {
  const dateTo = subtractDays(1, new Date());
  let dateFrom;
  switch (period) {
    case '1M':
      dateFrom = subtractMonths(1, dateTo);
      break;
    case '1Y':
      dateFrom = subtractYears(1, dateTo);
      break;
    case '5Y':
      dateFrom = subtractYears(5, dateTo);
      break;
    case 'YTD':
    default:
      dateFrom = new Date(dateTo.getFullYear(), 0, 0);
      break;
  }

  return [getAsString(dateFrom), getAsString(dateTo)];
};

const getPeriodArray = (t, initialDate) => {
  const { date } = initialDate || {};
  if (!date) return [];

  const today = getToday();
  const lastYear = getYear() - 1;
  const fiveYearsAgo = getYear() - 5;
  const currentMonth = new Date(today).getMonth();
  const currentDate = new Date(today).getDate();

  const ytd = new Date(lastYear, 11, 31);
  const y1 = new Date(lastYear, currentMonth, currentDate);
  // const y3 = new Date(lastYear - 2, currentMonth, currentDate);
  const y5 = new Date(fiveYearsAgo, currentMonth, currentDate);

  return [
    {
      label: t('PERIOD_INCEPTION'),
      key: ANALYSIS.PERFORMANCE_TIME.INCEPTION,
    }, {
      label: t('PERIOD_M1'),
      key: ANALYSIS.PERFORMANCE_TIME.M1,
      disabled: ytd.getTime() < new Date(date).getTime(),
    }, {
      label: t('PERIOD_Y1'),
      key: ANALYSIS.PERFORMANCE_TIME.Y1,
      disabled: y1.getTime() < new Date(date).getTime(),
    }, {
      label: t('PERIOD_Y5'),
      key: ANALYSIS.PERFORMANCE_TIME.Y1,
      disabled: y5.getTime() < new Date(date).getTime(),
    }, {
      label: t('PERIOD_YTD'),
      key: ANALYSIS.PERFORMANCE_TIME.YTD,
      disabled: y1.getTime() < new Date(date).getTime(),
    // }, {
    //   label: t('PERIOD_LABEL'),
    //   key: ANALYSIS.PERFORMANCE_TIME.PERIOD,
    //   disabled: y3.getTime() < new Date(date).getTime(),
    },
  ];
};

const sortTransactions = (transactions, key = TRANSACTIONS_SORT_KEYS.AMOUNT) => {
  if (!transactions?.length) return [];
  const transactionsMapped = {};

  // eslint-disable-next-line array-callback-return
  transactions.map((item) => {
    const mapKey = item?.id;
    transactionsMapped[mapKey] = transactionsMapped[mapKey] || [];
    transactionsMapped[mapKey].push(item);
  });

  const sortable = [];
  for (const compositionKey in transactionsMapped) {
    if (Object.prototype.hasOwnProperty.call(transactionsMapped, compositionKey)) {
      sortable.push({ [compositionKey]: transactionsMapped[compositionKey] });
    }
  }

  sortable.sort((a, b) => {
    if (a.total) return -1;
    const aName = Object.keys(a)[0];
    const bName = Object.keys(b)[0];

    a[aName].sort((c, d) => {
      if (c.level) return 0;
      return d[key] - c[key];
    });

    return b[bName][0][key] - a[aName][0][key];
  });

  let sortedComposition = [];
  for (let i = 0; i < sortable.length; i += 1) {
    const keyName = Object.keys(sortable[i])[0];
    sortedComposition = sortedComposition.concat(sortable[i][keyName]);
  }

  return sortedComposition;
};

const sortPositions = (positions, key = POSITIONS_SORT_KEYS.WEIGHT) => {
  if (!positions?.length) return [];
  const positionsMapped = {};

  // eslint-disable-next-line array-callback-return
  positions.map((item) => {
    const mapKey = item?.relationId || item?.level;
    positionsMapped[mapKey] = positionsMapped[mapKey] || [];
    positionsMapped[mapKey].push(item);
  });

  const sortable = [];
  for (const compositionKey in positionsMapped) {
    if (Object.prototype.hasOwnProperty.call(positionsMapped, compositionKey)) {
      sortable.push({ [compositionKey]: positionsMapped[compositionKey] });
    }
  }

  sortable.sort((a, b) => {
    if (a.total) return -1;
    const aName = Object.keys(a)[0];
    const bName = Object.keys(b)[0];

    a[aName].sort((c, d) => {
      if (c.level) return 0;
      return d[key] - c[key];
    });

    return b[bName][0][key] - a[aName][0][key];
  });

  let sortedComposition = [];
  for (let i = 0; i < sortable.length; i += 1) {
    const keyName = Object.keys(sortable[i])[0];
    sortedComposition = sortedComposition.concat(sortable[i][keyName]);
  }

  return sortedComposition;
};

const getIndexSerie = async (instrumentId, dateFrom, dateTo, currency) => {
  const bodyserie = {
    currency,
    dateFrom,
    dateTo,
    items: [{ instrumentId, weight: 1 }],
  };

  return http.post(API_ROUTES.PERFORMANCE_INDEX, bodyserie);
};

const getBenchmarkComposition = async (compositions) => {
  if (!compositions?.length) return [];
  const composition = compositions[0]?.items;

  const instrumentIds = composition?.map(({ instrumentId }) => instrumentId);
  const { data: instruments } = await http.get(`${API_ROUTES.INSTRUMENTS}/search`, { 'instrumentIds[]': instrumentIds });
  return composition.map((item) => {
    const instrumentIndex = instruments.findIndex(({ id }) => id === item?.instrumentId);

    return {
      instrument: instrumentIndex !== -1 ? instruments[instrumentIndex] : undefined,
      ...item,
    };
  });
};

const getBenchmarkSerie = async (benchmarkId, dateFrom, dateTo, currency) => {
  const benchmark = await http.get(`${API_ROUTES.BENCHMARKS}/${benchmarkId}`);
  if (!benchmark?.compositions?.length) return null;

  const bodyserie = {
    currency,
    dateFrom,
    dateTo,
    compositions: benchmark?.compositions,
  };

  const [response, composition] = await Promise.all([
    http.post(API_ROUTES.PERFORMANCE_BACKTESTING, bodyserie),
    getBenchmarkComposition(benchmark?.compositions),
  ]);

  return [response, benchmark, composition];
};

const SCATTER_NAMES = {
  0: 'DASHBOARD_PERFORMANCE_PORTFOLIO',
  1: 'RATIOS_EONIA',
  2: 'RATIOS_MSCI',
  3: 'RATIOS_BENCHMARK',
};

const SCATTER_SIZES = {
  SMALL: 75,
  LARGE: 150,
};

const sanitizeScatterData = (ratios, t) => {
  const [portfolio, eona, msduwiSerie, benchmark] = ratios;

  const dataArray = [];
  [portfolio, eona, msduwiSerie, benchmark].forEach((mbjData, index) => {
    if (mbjData && mbjData.volatilityAnnualized && mbjData.performanceAnnualized) {
      dataArray.push({
        volatilityAnnualized: mbjData.volatilityAnnualized,
        performanceAnnualized: mbjData.performanceAnnualized,
        name: t(SCATTER_NAMES[index]),
        size: index === 0 ? SCATTER_SIZES.LARGE : SCATTER_SIZES.SMALL,
      });
    }
  });

  return dataArray;
};

const isValidPeriodForRatios = (dateFrom, dateTo) => {
  let valueDateFrom = getFromString(dateFrom);
  const valueDateTo = getFromString(dateTo);

  valueDateFrom = addYears(1, valueDateFrom);
  if (valueDateFrom.getTime() > valueDateTo.getTime()) return false;

  return true;
};

const hasTransaction3Years = (dateAsString) => {
  const minDate = getFromString(dateAsString);
  const threeYears = subtractYears(3, getToday());
  const y3 = minDate.getTime() <= threeYears.getTime();

  return y3;
};

const clearCurrencySign = (value) => (value ? value?.replace(/£/g, '') : '');

function getPositionName(item, t) {
  if (item?.level === 'total') return t('POSITIONS_TABLE_TOTAL');
  return item?.level ? (item?.relation?.name || item?.relationId) : (item?.instrument?.name || t('POSITION_PENDING'));
}

const DASHBOARD = {
  getContracts,
  getPortfolioIds,
  parsePerformanceSerie,
  getPeriodArray,
  getPeriodDates,
  hasTransaction3Years,
  sortPositions,
  sortTransactions,
  getIndexSerie,
  getBenchmarkSerie,
  sanitizeScatterData,
  isValidPeriodForRatios,
  POSITIONS_SORT_KEYS,
  TRANSACTIONS_SORT_KEYS,
  clearCurrencySign,
  getPositionName,
  getPeriodDateHelper,
};

export default DASHBOARD;

export const saveToStorage = (variable, value, expires, isSessionStorage = false) => {
  try {
    const data = { value, expires_at: expires.getTime() };
    if (isSessionStorage) {
      sessionStorage.setItem(variable.toString(), JSON.stringify(data));
    } else {
      localStorage.setItem(variable.toString(), JSON.stringify(data));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const loadFromStorage = (localStorageKey) => {
  let data = JSON.parse(sessionStorage.getItem(localStorageKey.toString()));

  if (!data) data = JSON.parse(localStorage.getItem(localStorageKey.toString()));

  if (!data) return null;

  if (data.expires_at !== null && data.expires_at < new Date().getTime()) {
    localStorage.removeItem(localStorageKey.toString());
    sessionStorage.removeItem(localStorageKey.toString());
    return null;
  }

  return data.value;
};

export const removeFromStorage = (localStorageKey) => {
  try {
    localStorage.removeItem(localStorageKey);
    sessionStorage.removeItem(localStorageKey);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const dropStorage = (isSession = false) => {
  if (isSession) {
    sessionStorage.clear();
  } else {
    localStorage.clear();
  }
};

import { getYear, getToday, getAsString, getTodayAsString, getFromString, subtractYears } from '@allfundsbank/np-date';
import { ANALYSIS } from 'src/enums';

const SORT_KEYS = {
  DATE: 'documentDate',
  NAME: 'name',
  TYPE: 'docType',
};

const getPeriodArray = (t, initialDate, customPeriod) => {
  const { date } = initialDate || {};
  if (!date) return [];

  const periodsOptions = [
    {
      label: t('PERIOD_M1'),
      key: ANALYSIS.PERFORMANCE_TIME.M1,
    }, {
      label: t('PERIOD_Y1'),
      key: ANALYSIS.PERFORMANCE_TIME.Y1,
    }, {
      label: t('PERIOD_Y5'),
      key: ANALYSIS.PERFORMANCE_TIME.Y5,
    }, {
      label: t('PERIOD_YTD'),
      key: ANALYSIS.PERFORMANCE_TIME.YTD,
    },
  ];

  if (customPeriod) periodsOptions.push({ label: t('CUSTOM_PERIOD_LABEL'), key: ANALYSIS.PERFORMANCE_TIME.PERIOD });

  return periodsOptions;
};

const getPeriodDates = (period, initialDate) => {
  // eslint-disable-next-line no-param-reassign
  period = period || ANALYSIS.PERFORMANCE_TIME.YTD;

  const today = getToday();
  const lastYear = getYear() - 1;
  const fiveYearsAgo = getYear() - 5;
  const currentMonth = new Date(today).getMonth();
  const currentDate = new Date(today).getDate();
  let dateFrom;

  const inceptionAsString = initialDate?.dateAsString || getTodayAsString();
  const inceptionDate = initialDate?.date ? new Date(initialDate?.date) : new Date();
  let ytd;
  let y1;
  let y5;
  let m1;

  switch (period) {
    case ANALYSIS.PERFORMANCE_TIME.YTD:
      ytd = new Date(lastYear, 11, 31);
      ytd = ytd.getTime() < inceptionDate.getTime() ? inceptionDate : ytd;
      dateFrom = getAsString(ytd);
      break;
    case ANALYSIS.PERFORMANCE_TIME.Y1:
      y1 = new Date(lastYear, currentMonth, currentDate);
      y1 = y1.getTime() < inceptionDate.getTime() ? inceptionDate : y1;
      dateFrom = getAsString(y1);
      break;
    case ANALYSIS.PERFORMANCE_TIME.Y5:
      y5 = new Date(fiveYearsAgo, currentMonth, currentDate);
      y5 = y5.getTime() < inceptionDate.getTime() ? inceptionDate : y5;
      dateFrom = getAsString(y5);
      break;
    case ANALYSIS.PERFORMANCE_TIME.M1:
      m1 = new Date(getYear(), currentMonth - 1, currentDate);
      m1 = m1.getTime() < inceptionDate.getTime() ? inceptionDate : m1;
      dateFrom = getAsString(m1);
      break;
    case ANALYSIS.PERFORMANCE_TIME.INCEPTION:
      dateFrom = inceptionAsString;
      break;
    default:
      break;
  }

  return [dateFrom, getTodayAsString()];
};

const hasTransaction3Years = (dateAsString) => {
  const minDate = getFromString(dateAsString);
  const threeYears = subtractYears(3, getToday());
  const y3 = minDate.getTime() <= threeYears.getTime();

  return y3;
};

const DOCUMENTATION = {
  getPeriodArray,
  getPeriodDates,
  hasTransaction3Years,
  SORT_KEYS,
};

export default DOCUMENTATION;

import { createModel } from '@rematch/core';

import { getAsString, getTodayAsString } from '@allfundsbank/np-date';
import { HttpInstance, getLang } from 'src/utils';
import { API_ROUTES, ANALYSIS } from 'src/enums';
import { SCREENER } from 'src/helpers';

const { getPeriodDates, hasInception5Years, getUpdatListQuery } = SCREENER;

const http = new HttpInstance();

const DEFAULT_LIMIT = 10;

const screener = createModel()({
  name: 'screener',
  state: {
    currencies: [],
    assetClasses: [],
    subAssetClasses: [],
    instrumentOptions: [],
    instrumentList: [],
    instrumentListPagination: {},
    performanceSerie: [],
    performance: {},
    benchmarkSerie: [],
    benchmarkPerformance: {},
    benchmark: null,
    period: null,
    inceptionDate: null,
  },
  reducers: {
    SET_PROP(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    RESET_STATE(state) {
      return {
        ...state,
        instrumentOptions: [],
        instrumentList: [],
        instrumentListPagination: {},
        performanceSerie: [],
        performance: {},
        benchmarkSerie: [],
        benchmarkPerformance: {},
        benchmark: null,
        distributions: {},
      };
    },
    RESET_ANALYSIS(state) {
      return {
        ...state,
        performanceSerie: [],
        performance: {},
        benchmarkSerie: [],
        benchmarkPerformance: {},
        benchmark: null,
        distributions: {},
        period: null,
        inceptionDate: null,
      };
    },
  },
  effects: (dispatch) => ({
    async filterInstrumentOptions(q, state) {
      const { visibilityLimitedToFod } = state.theme;

      const response = await http.get(`${API_ROUTES.INSTRUMENTS}/search`, {
        q, limit: 10, skip: 0, operativeOnly: visibilityLimitedToFod,
      });

      if (!response?.data?.length) {
        dispatch.screener.SET_PROP({ instrumentOptions: [] });
        return;
      }

      dispatch.screener.SET_PROP({ instrumentOptions: SCREENER.formatInstrumentOptions(response?.data || []) });
    },
    loadFormSelectors() {
      Promise.all([
        dispatch.screener.loadCurrencies(),
        dispatch.screener.loadAssetClass(),
        dispatch.screener.loadSubAssetClass(),
      ]);
    },
    async loadCurrencies(_, state) {
      const { currencies } = state.screener;
      if (currencies?.length) return;

      const response = await http.get(API_ROUTES.CURRENCIES);
      dispatch.screener.SET_PROP({ currencies: SCREENER.formatCurrenciesOptions(response?.data || []) });
    },
    async loadAssetClass(_, state) {
      const { assetClasses } = state.screener;
      if (assetClasses?.length) return;

      const response = await http.get(API_ROUTES.RELATIONS_ASSET_CLASS);
      dispatch.screener.SET_PROP({ assetClasses: SCREENER.formatAssetClassOptions(response?.data || []) });
    },
    async loadSubAssetClass(_, state) {
      const { subAssetClasses } = state.screener;
      if (subAssetClasses?.length) return;

      const response = await http.get(API_ROUTES.RELATIONS_SUB_ASSET_CLASS);
      dispatch.screener.SET_PROP({ subAssetClasses: SCREENER.formatAssetClassOptions(response?.data || []) });
    },
    async updateInstrumentList(params, state) {
      const { visibilityLimitedToFod } = state.theme;

      const { data: rawInstruments, metadata } = await http.get(`${API_ROUTES.INSTRUMENTS}/search`, getUpdatListQuery(params, visibilityLimitedToFod));
      dispatch.screener.SET_PROP({
        instrumentList: rawInstruments,
        instrumentListPagination: {
          ...metadata?.pagination,
          totalPages: Math.ceil((metadata?.pagination?.total || 0) / DEFAULT_LIMIT),
          currentPage: params.page || 1,
        },
      });

      dispatch.screener.loadCompleteInstrumentData(rawInstruments);
    },
    async loadCompleteInstrumentData(rawInstruments, state) {
      const { entityAnalysis } = state.auth;
      const { currency } = entityAnalysis || {};

      const promises = [];
      promises.push(http.post(API_ROUTES.RISK_RATIOS_ITEMS, SCREENER.getRatiosBody(rawInstruments, currency, ANALYSIS.PERFORMANCE_TIME.Y1)));
      promises.push(http.post(API_ROUTES.RISK_RATIOS_ITEMS, SCREENER.getRatiosBody(rawInstruments, currency, ANALYSIS.PERFORMANCE_TIME.YTD)));

      for (let i = 0; i < rawInstruments?.length; i += 1) {
        promises.push(dispatch.screener.completeInstrumentData(rawInstruments[i]));
      }

      const [y1Ratios, ytdRatios, ...instruments] = await Promise.all(promises);
      let performanceInstruments = SCREENER.setPerformanceRatios(instruments, y1Ratios?.data, ANALYSIS.PERFORMANCE_TIME.Y1);
      performanceInstruments = SCREENER.setPerformanceRatios(performanceInstruments, ytdRatios?.data, ANALYSIS.PERFORMANCE_TIME.YTD);

      dispatch.screener.SET_PROP({ instrumentList: performanceInstruments });
      return performanceInstruments;
    },
    async completeInstrumentData(instrument, state) {
      const { client } = state.auth;
      const lang = getLang();

      const entity = client?.entity?.id || client?.entity;
      const currentItem = { instrumentId: instrument.id, weight: 1 };

      const [responseCategories, responsePrices] = await Promise.all([
        http.post(`${API_ROUTES.RELATIONS}?lang=${lang}`, { relationType: 'categories', items: [currentItem] }),
        http.get(`${API_ROUTES.PRICES}/${instrument.id}`, { last: true, entity }),
      ]);

      return {
        ...instrument,
        category: responseCategories?.data?.length ? responseCategories?.data[0] : {},
        price: responsePrices?.data?.length ? responsePrices?.data[0] : {},
      };
    },
    async getBasicData(id, state) {
      const { instrumentList = [] } = state.screener;
      const instrumentsFiltered = instrumentList.filter((instrument) => instrument?.id === id);

      const instrument = await http.get(`${API_ROUTES.INSTRUMENTS}/${id}`, { 'populates[]': ['extra-data'] });
      let rawInstrument = {};
      if (!instrumentsFiltered?.length) {
        [rawInstrument] = await dispatch.screener.loadCompleteInstrumentData([instrument]);
      }

      if (instrument?.structural?.address) {
        const { data: country } = await http.get(API_ROUTES.COUNTRIES, { 'cca2[]': instrument?.structural.address });
        instrument.structural.address = country?.length ? country[0] : undefined;
      }

      const inception5Years = hasInception5Years(instrument?.data?.inceptionDate);
      const period = inception5Years ? ANALYSIS.PERFORMANCE_TIME.Y5 : undefined;
      await dispatch.screener.SET_PROP({ inceptionDate: { date: instrument?.data?.inceptionDate, dateAsString: getAsString(instrument?.data?.inceptionDate) } });

      dispatch.screener.loadInstrumentAnalysis({ instrumentId: instrument?.id, period });
      return instrumentsFiltered?.length ? { ...instrument, ...instrumentsFiltered[0] } : { ...instrument, ...rawInstrument };
    },
    async loadInstrumentAnalysis({ instrumentId, period }) {
      await dispatch.screener.SET_PROP({ period });

      await Promise.all([
        dispatch.screener.loadInstrumentPerformance(instrumentId),
        dispatch.screener.loadDistributions(instrumentId),
      ]);
    },
    async loadInstrumentPerformance(instrumentId, state) {
      const { currency } = state.auth.entityAnalysis;
      const { period, inceptionDate } = state.screener;
      const [dateFrom, dateTo] = getPeriodDates(period, inceptionDate);

      const body = {
        currency,
        dateFrom,
        dateTo,
        items: [{ instrumentId, weight: 1 }],
      };

      const promises = [http.post(API_ROUTES.PERFORMANCE_INDEX, body)];
      try {
        const peerGroup = await http.get(`${API_ROUTES.INSTRUMENT_PEER_GROUP}/${instrumentId}`);
        if (peerGroup?.code) {
          const benchResponse = await http.get(`${API_ROUTES.INSTRUMENT_PEER_GROUP_BENCHMARK}/${peerGroup?.code}`);

          promises.push(http.post(API_ROUTES.PERFORMANCE_INDEX, { ...body, items: [{ instrumentId: benchResponse?.benchmark, weight: 1 }] }));
          promises.push(http.get(`${API_ROUTES.INSTRUMENTS}/${benchResponse?.benchmark}`));
        }
      } catch {
        // eslint-disable-next-line no-console
        console.warn('No benchmark asociated');
      }

      const [response, responseBenchmark, benchmark] = await Promise.all(promises);
      const { serie: performanceSerie, performance } = response || {};
      const { serie: benchmarkSerie, performance: benchmarkPerformance } = responseBenchmark || {};

      dispatch.screener.SET_PROP({
        performanceSerie,
        performance,
        benchmarkSerie,
        benchmarkPerformance,
        benchmark,
      });
    },
    async loadDistributions(instrumentId, state) {
      const lang = getLang();
      if (!instrumentId) return;

      const body = {
        date: getTodayAsString(),
        distributionTypes: ANALYSIS.DISTRIBUTION_TYPES_INSTRUMENTS,
        other: true,
        group: 8,
        items: [{ instrumentId, weight: 1 }],
      };

      const distributions = await http.post(`${API_ROUTES.DISTRIBUTIONS}?lang=${lang}`, body);
      dispatch.screener.SET_PROP({ distributions: { ...distributions } });
    },
    // async downloadProductSheet(isin) {
    //   const URL = `${window.appConfig.URL_CONNECT}/product/${isin}`;
    //   window.open(URL, '_blank');
    // },
  }),
});

export default screener;

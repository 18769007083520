import { createModel } from '@rematch/core';
import DEFAULT_THEME from 'src/theme';

import { HttpInstance } from 'src/utils';
import { LAYOUT, API_ROUTES } from 'src/enums';

const { DEFAULT_MINH_CARDS } = LAYOUT;

const DEFAULT_POSITIONS_GROUP = 'subassetclass';
const { DEFAULT_DASHBOARD_LAYOUT, DEFAULT_DISTRIBUTIONS_LAYOUT } = LAYOUT;
const http = new HttpInstance();

const theme = createModel()({
  name: 'theme',
  state: {
    webTheme: DEFAULT_THEME,
    completeThemeLoaded: false,
    systemLayout: DEFAULT_DASHBOARD_LAYOUT,
    distributionsLayout: DEFAULT_DISTRIBUTIONS_LAYOUT,
    positionsGroupBy: DEFAULT_POSITIONS_GROUP,
    visibilityLimitedToFod: false,
  },
  reducers: {
    SET_PROP(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    RESET_LAYOUT(state) {
      return {
        ...state,
        systemLayout: DEFAULT_DASHBOARD_LAYOUT,
        layoutLoaded: false,
      };
    },
    SET_LAYOUT(state, payload) {
      return {
        ...state,
        systemLayout: [
          ...payload,
        ],
      };
    },
    SET_ANALYSIS(state, payload) {
      return {
        ...state,
        positionsGroupBy: payload || DEFAULT_POSITIONS_GROUP,
      };
    },
  },
  effects: (dispatch) => ({
    async loadLayout(payload, state) {
      const { selectedOrganisationId: organisationId } = state?.dashboard;

      const response = await http.get(API_ROUTES.USER_SETTINGS, { organisationId });

      if (response?.gridLayout?.length) {
        await dispatch.theme.SET_LAYOUT(response?.gridLayout?.map((item) => ({ ...item, minH: DEFAULT_MINH_CARDS })));
      } else {
        await dispatch.theme.RESET_LAYOUT();
        await dispatch.theme.adjustPositionsCardHeight();
      }
    },
    async updateLayout(gridLayout, state) {
      const { selectedOrganisationId: organisationId } = state?.dashboard;
      if (!gridLayout?.length || !organisationId) return;
      await dispatch.theme.SET_LAYOUT(gridLayout);
      await http.put(API_ROUTES.USER_SETTINGS, { gridLayout, organisationId });
    },
    async addRemoveSection(sectionKey, state) {
      const { systemLayout = [] } = state.theme;
      if (!sectionKey) return;

      const index = systemLayout.findIndex(({ i }) => i === sectionKey);
      if (index !== -1) {
        dispatch.theme.updateLayout(systemLayout.filter(({ i }) => i !== sectionKey));
      } else {
        const addLayout = DEFAULT_DASHBOARD_LAYOUT.filter(({ i }) => i === sectionKey);
        dispatch.theme.updateLayout([...systemLayout, ...addLayout]);
      }
    },
    async adjustPositionsCardHeight(payload, state) {
      const positionsLength = state?.dashboard?.positions?.length || 0;
      let h;
      switch (positionsLength) {
        case 0 || 1:
          h = 8;
          break;
        case 2:
          h = 9;
          break;
        case 3:
          h = 10;
          break;
        default:
          h = 12;
          break;
      }
      const updatedLayout = state?.theme?.systemLayout?.map((item) => {
        if (item?.i !== 'positions-card') return item;
        return { ...item, h };
      });
      await dispatch.theme.SET_LAYOUT(updatedLayout);
    },
  }),
});

export default theme;

import { saveAs } from 'file-saver';
import { createModel } from '@rematch/core';
import isEqual from 'lodash.isequal';
import { toast } from 'react-toastify';
import { displayErrorMsg, displayLoadingMsg, updateSuccessMsg, updateErrorMsg, endLoadingMsg, HttpInstance } from 'src/utils';
import { DOCUMENTATION } from 'src/helpers';
import { API_ROUTES, MIME_TYPES } from 'src/enums';

const { getPeriodDates } = DOCUMENTATION;

const http = new HttpInstance();
export const DEFAULT_REPORTS_LIMIT = 10;
const DEFAULT_PERIOD_OPTION = '1M';
const PERIOD_SELECTOR_TAG = 'period';

const documentation = createModel()({
  name: 'documentation',
  state: {
    loading: false,
    clientNotInizializated: true,
    reports: [],
    reportsFNZ: [],
    totalDocuments: 0,
    selectedReports: [],
    reportsTotal: 0,
    reportsTotalFNZ: 0,
    documentsByOrganisation: {},
    filterReports: {
      period: DEFAULT_PERIOD_OPTION,
      currentPage: 1,
      sortKey: 'documentDate',
      sort: 'DSC',
      documentDate: undefined,
    },
    filterReportsFNZ: {
      currentPage: 1,
      period: DEFAULT_PERIOD_OPTION,
    },
    filterStatementsReportsFNZ: {
      currentPage: 1,
      period: DEFAULT_PERIOD_OPTION,
    },
    paramsReports: {},
    filterAccounts: {
      currentPage: 1,
      sortKey: undefined,
      sort: 'DSC',
      q: undefined,
    },
    selectedAccount: {
      id: undefined,
      accountData: {},
    },
    filterSelectedAccount: {
      period: DEFAULT_PERIOD_OPTION,
      type: undefined,
      currentPage: 1,
      sortKey: 'documentDate',
      sort: 'DSC',
    },
    filterSelectedAccountFNZ: {
      period: DEFAULT_PERIOD_OPTION,
      type: undefined,
      currentPage: 1,
      sortKey: 'documentDate',
      sort: 'DSC',
    },
    paramsSelectedAccount: {},
    selectsDataLoaded: false,
    initialDate: {
      date: '1900-01-01T23:00:00.000Z',
      dateAsString: '19000101',
    },
  },
  reducers: {
    SET_PROP(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    RESET(state) {
      return {
        ...state,
        clientNotInizializated: true,
        period: DEFAULT_PERIOD_OPTION,
        documentsByOrganisation: {},
        reports: [],
        reportsFNZ: [],
        reportsTotal: 0,
        reportsTotalFNZ: 0,
        filterReports: {
          currentPage: 1,
          sortKey: 'date',
          sort: 'DSC',
        },
        filterReportsFNZ: { currentPage: 1 },
      };
    },
    RESET_DETAIL(state) {
      return {
        ...state,
        selectedAccount: {
          id: undefined,
          accountData: {},
        },
        filterSelectedAccount: {
          period: DEFAULT_PERIOD_OPTION,
          type: undefined,
          currentPage: 1,
          sortKey: 'documentDate',
          sort: 'DSC',
        },
        filterSelectedAccountFNZ: {
          period: DEFAULT_PERIOD_OPTION,
          type: undefined,
          currentPage: 1,
          sortKey: 'documentDate',
          sort: 'DSC',
        },
        paramsSelectedAccount: {},
      };
    },
  },
  effects: (dispatch) => ({
    async setProp(payload) {
      dispatch.documentation.SET_PROP(payload);
    },
    async setFilterReports(payload, state) {
      const { filterReports } = state.documentation;
      dispatch.documentation.SET_PROP({ filterReports: { ...filterReports, ...payload } });
    },
    async setFilterReportsFNZ(payload, state) {
      const { filterReportsFNZ } = state.documentation;
      dispatch.documentation.SET_PROP({ filterReportsFNZ: { ...filterReportsFNZ, ...payload } });
    },
    async setFilterStatementsReportsFNZ(payload, state) {
      const { filterStatementsReportsFNZ } = state.documentation;
      dispatch.documentation.SET_PROP({ filterStatementsReportsFNZ: { ...filterStatementsReportsFNZ, ...payload } });
    },
    async setFilterAccounts(payload, state) {
      const { filterAccounts } = state.documentation;
      dispatch.documentation.SET_PROP({ filterAccounts: { ...filterAccounts, ...payload } });
    },
    async setFilterAccountsReportsFNZ(payload, state) {
      const { filterSelectedAccountFNZ } = state.documentation;
      dispatch.documentation.SET_PROP({ filterSelectedAccountFNZ: { ...filterSelectedAccountFNZ, ...payload } });
    },
    async setFilterSelectedAccount(payload, state) {
      const { filterSelectedAccount } = state.documentation;
      dispatch.documentation.SET_PROP({ filterSelectedAccount: { ...filterSelectedAccount, ...payload } });
    },
    async syncState(payload, state) {
      if (payload) return state[payload];
      return state;
    },
    async loadSelectsData(accountId, state) {
      const { selectedOrganisationId } = state.dashboard;
      
      const params = {
          taAccountIds: [accountId],
          organisationIds: [selectedOrganisationId],
      }
      if (state?.documentation?.selectsDataLoaded === params || !selectedOrganisationId ) return;

      const { data: docTypes } = await http.get(API_ROUTES.DOCUMENT_TYPES, params);
      const typeOptions = docTypes?.map((item, i) => ({ id: i + 1, name: item }));
      await dispatch.documentation.SET_PROP({ docTypes:typeOptions, selectsDataLoaded: params });
    },
    async loadPage(_payload, state) {
      if (state.dashboard.organisations.length === 0) await dispatch.dashboard.loadOrganisations();
      dispatch.documentation.loadSelectsData();
      await dispatch.documentation.loadReports();
      await dispatch.documentation.loadReportsFNZ();
      await dispatch.documentation.loadQuarterlyReportsFNZ();
      await dispatch.documentation.loadReportsTypesFNZ();
      await dispatch.documentation.loadAccountsTotalReports();
      await dispatch.documentation.SET_PROP({ clientNotInizializated: false });
    },
    async loadReports(_payload, _state) {
      let state = _state;
      const { selectedReports } = state.documentation;
      if (state.dashboard.organisations.length === 0 || !state.dashboard.selectedOrganisationId) {
        await dispatch.dashboard.loadOrganisations();
      }

      const { selectedOrganisationId } = state.dashboard;
      state = await dispatch.documentation.syncState();

      const { initialDate } = state.documentation;
      const { sort, sortKey, currentPage, period } = state.documentation.filterReports;
      let { dateFrom, dateTo } = state.documentation.filterReports;
      if (period !== PERIOD_SELECTOR_TAG) {
        [dateFrom, dateTo] = getPeriodDates(period, initialDate);
      }

      const params = {
        'organisationIds[]': [selectedOrganisationId],
        skip: ((currentPage || 1) - 1) * DEFAULT_REPORTS_LIMIT || 0,
        limit: DEFAULT_REPORTS_LIMIT,
        documentDateFrom: dateFrom,
        documentDateTo: dateTo,
        sort,
        sortKey,
      };

      // Comparation between last params to avoid unnecessaries api calls
      if (isEqual(params, state.documentation.paramsReports)) return;

      const response = await http.get(API_ROUTES.REPORTS, params);
      const { data = [], metadata = {} } = response || {};
      await dispatch.documentation.SET_PROP({
        reports: data,
        totalDocuments: metadata?.pagination?.total,
        selectedReports: selectedReports || [],
        reportsTotal: metadata?.pagination?.total || 0,
        paramsReports: params,
      });
    },
    async loadAccountsTotalReports(payload, _state) {
      let state = _state;

      if (state.dashboard.organisations.length === 0 || !state.dashboard.selectedOrganisationId) {
        await dispatch.dashboard.loadOrganisations();
      }
      const { filterAccounts } = state.documentation;
      const { currentPage, q, sortKey, sort } = filterAccounts;

      const { selectedOrganisationId } = state.dashboard;

      state = await dispatch.documentation.syncState();
      const params = {
        skip: (currentPage - 1) * DEFAULT_REPORTS_LIMIT || 0,
        limit: DEFAULT_REPORTS_LIMIT,
        sortKey: sortKey || undefined,
        sort: sort || undefined,
        q: q || undefined,
      };

      const documentsByOrganisation = await http.get(`${API_ROUTES.DOCUMENT_REPOSITORY_ORGANISATION_COUNT}/${selectedOrganisationId}`, params);

      dispatch.documentation.SET_PROP({
        accountsDocuments: documentsByOrganisation?.data || [],
        totalAccountsDocuments: documentsByOrganisation?.metadata?.pagination?.total || 0,
      });
    },
    async loadAccountReports(accountId, _state) {
      let state = _state;
      if (state.dashboard.organisations.length === 0 || !state.dashboard.selectedOrganisationId) {
        await dispatch.dashboard.loadOrganisations();
      }
      await dispatch.documentation.loadSelectsData(accountId);
      await dispatch.documentation.loadSelectedAccount(accountId);
      state = await dispatch.documentation.syncState();

      const { selectedOrganisationId, docTypes } = state.dashboard;
      const { selectedAccount, filterSelectedAccount, initialDate } = state.documentation;
      const { period, type, currentPage, sort, sortKey } = filterSelectedAccount;

      const docType = docTypes?.filter((i)=> i.key === type )?.map((item)=> item.name)
      let { dateFrom, dateTo } = filterSelectedAccount;
      if (period !== PERIOD_SELECTOR_TAG) {
        [dateFrom, dateTo] = getPeriodDates(period, initialDate);
      }

      const params = {
        'organisationIds[]': [selectedOrganisationId],
        skip: (currentPage - 1) * DEFAULT_REPORTS_LIMIT || 0,
        limit: DEFAULT_REPORTS_LIMIT,
        documentDateFrom: dateFrom,
        documentDateTo: dateTo,
        'taAccountIds[]': [accountId],
        'docTypes[]': docType,
        sort,
        sortKey,
      };
      // Comparation between last params to avoid unnecessaries api calls
      if (isEqual(params, state.documentation.paramsSelectedAccount)) return;

      const response = await http.get(API_ROUTES.REPORTS, params);
      const { data = [], metadata = {} } = response || {};
      await dispatch.documentation.SET_PROP({
        selectedAccount: {
          ...selectedAccount,
          reports: data,
          reportsTotal: metadata?.pagination?.total || 0,
        },
        paramsSelectedAccount: params,
      });
    },
    async loadReportsFNZ(payload, state) {
      if (state.dashboard.organisations.length === 0 || !state.dashboard.selectedOrganisationId) {
        await dispatch.dashboard.loadOrganisations();
      }

      const { selectedOrganisationId } = state.dashboard;
      const { filterReportsFNZ, initialDate } = state.documentation;
      const { period, currentPage, type } = filterReportsFNZ;
      let { dateFrom, dateTo } = filterReportsFNZ;
      if (period !== PERIOD_SELECTOR_TAG) [dateFrom, dateTo] = getPeriodDates(period, initialDate);

      const params = {
        clientId: selectedOrganisationId,
        page: currentPage || 1,
        dateFrom,
        dateTo,
        docType: type || undefined,
      };

      if (isEqual(params, state.documentation.paramsSelectedAccount)) return;

      const response = await http.get(API_ROUTES.REPORTS_FNZ, params);
      await dispatch.documentation.SET_PROP({
        reportsFNZ: response?.data || [],
        reportsTotalFNZ: response?.metadata?.pagination?.total || 0,
      });
    },
    async loadQuarterlyReportsFNZ(payload, state) {
      if (state.dashboard.organisations.length === 0 || !state.dashboard.selectedOrganisationId) {
        await dispatch.dashboard.loadOrganisations();
      }

      const { selectedOrganisationId } = state.dashboard;
      const { filterStatementsReportsFNZ, initialDate } = state.documentation;
      const { period, currentPage } = filterStatementsReportsFNZ;
      let { dateFrom, dateTo } = filterStatementsReportsFNZ;
      if (period !== PERIOD_SELECTOR_TAG) [dateFrom, dateTo] = getPeriodDates(period, initialDate);

      const params = {
        clientId: selectedOrganisationId,
        page: currentPage || 1,
        dateFrom,
        dateTo,
        docType: 21,
      };

      if (isEqual(params, state.documentation.paramsSelectedAccount)) return;

      const response = await http.get(API_ROUTES.REPORTS_FNZ, params);
      await dispatch.documentation.SET_PROP({
        statementReportsFNZ: response?.data || [],
        statementsReportsTotalFNZ: response?.metadata?.pagination?.total || 0,
      });
    },
    async loadAccountReportsFNZ(payload, _state) {
      const { accountId } = payload;
      let state = _state;

      await dispatch.documentation.loadSelectsData(accountId);
      await dispatch.documentation.loadSelectedAccount(accountId);
      state = await dispatch.documentation.syncState();

      const { filterSelectedAccountFNZ, initialDate } = state.documentation;
      const { period, currentPage, type } = filterSelectedAccountFNZ;

      const [dateFrom, dateTo] = period ? getPeriodDates(period, initialDate) : [undefined, undefined];

      const params = {
        page: currentPage || 1,
        dateFrom,
        dateTo,
        docType: type || undefined,
        accountIds: accountId ? [accountId] : undefined,
      };

      // Comparation between last params to avoid unnecessaries api calls
      if (isEqual(params, state.documentation.paramsSelectedAccount)) return;

      const response = await http.get(API_ROUTES.REPORTS_FNZ, params);
      const { data = [], metadata = {} } = response || {};
      await dispatch.documentation.SET_PROP({
        reportsFNZ: data,
        reportsTotalFNZ: metadata?.pagination?.total || 0,
      });
    },
    async loadReportsTypesFNZ() {
      const docType = await http.get(API_ROUTES.REPORTS_TYPES_FNZ);
      await dispatch.documentation.SET_PROP({ reportsTypesFNZ: docType?.data });
    },
    async loadSelectedAccount(accountId, state) {
      if (state?.documentation?.selectedAccount?.id === accountId) return;
      const params = {
        limit: 1,
        'portfolioIds[]': [accountId],
      };
      const response = await http.get(API_ROUTES.ACCOUNTS, params);
      const { data = [] } = response || {};
      const accountData = data?.length ? data[0] : {};
      await dispatch.documentation.SET_PROP({ selectedAccount: { id: accountId, accountData } });
    },
    async downloadReport(payload) {
      const { id, name, fileExtension } = payload;
      const fileName = `${name}.${fileExtension}`;
      const file = await http.downloadBlob(`${API_ROUTES.DOCUMENT_REPOSITORY}/${id}/download`);
      saveAs(file, fileName);
    },
    async downloadFNZReport(payload) {
      try {
        const { id } = payload;
        const file = await http.get(`${API_ROUTES.DOWNLOAD_FNZ_REPORT}/${id}`);
        const { docImage, fileName } = file;
        const re = /(?:\.([^.]+))?$/;
        const ext = re.exec(fileName)[1].toLowerCase();
        const extension = MIME_TYPES[ext];

        if (!extension) {
          displayErrorMsg('This file not have a valid extension');
          return;
        }

        const linkSource = `data:${extension};base64,${docImage}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (err) {
        displayErrorMsg('Error while downloading the document');
      }
    },
    async downloadMultiplesReports(_, state) {
      const { selectedReports } = state.documentation;

      if (selectedReports.length <= 10) {
        let successDownloads = 0;
        const toastId = displayLoadingMsg(`Starting document download (${selectedReports.length})`);
        for (let i = 0; i < selectedReports.length; i += 1) {
          const report = selectedReports[i];
          try {
            // eslint-disable-next-line no-await-in-loop
            await dispatch.documentation.downloadReport(report);
            updateSuccessMsg(toastId, { msg: `${i + 1}/${selectedReports.length} documents downloaded` });
            successDownloads += 1;
          } catch (e) {
            updateErrorMsg(toastId, { msg: `Error downloading ${i + 1} document` });
          }
        }
        endLoadingMsg(toastId, { msg: `Downloaded ${successDownloads}/${selectedReports.length}` });
      } else {
        const body = { ids: selectedReports.map((reports) => reports.id) };

        try {
          const reports = await toast.promise(
            http.post(`${API_ROUTES.DOCUMENT_REPOSITORY}/download-multiple`, body, { responseType: 'blob', forceCrash: true }),
            {
              pending: 'Downloading documents',
              success: 'Documents downloaded successfully',
            },
          );
          saveAs(reports, 'CCLA_DIGITAL_PORTAL_REPORTS.zip');
        } catch (e) {
          displayErrorMsg('Error while downloading documents');
        }
      }
    },
    async handleSelectDocument({ report }, state) {
      const { selectedReports } = state.documentation;

      if (!selectedReports.some((item) => item.id === report.id) && selectedReports) {
        dispatch.documentation.SET_PROP({ selectedReports: [...selectedReports, report] });
        return;
      }

      const newSelectedReports = selectedReports.filter((item) => item.id !== report.id);
      dispatch.documentation.SET_PROP({ selectedReports: newSelectedReports });
    },
    async handleSelectAllDocuments(_, state) {
      const { selectedOrganisationId } = state.dashboard;
      const { initialDate, filterReports, selectedReports, totalDocuments } = state.documentation;
      const { sort, sortKey, period } = filterReports;
      let { dateFrom, dateTo } = state.documentation.filterReports;

      if (selectedReports && selectedReports.length && selectedReports.length === totalDocuments) {
        dispatch.documentation.SET_PROP({ selectedReports: [] });
        return;
      }

      if (period !== PERIOD_SELECTOR_TAG) {
        [dateFrom, dateTo] = getPeriodDates(period, initialDate);
      }

      const params = {
        'organisationIds[]': [selectedOrganisationId],
        documentDateFrom: dateFrom,
        documentDateTo: dateTo,
        sort,
        sortKey,
      };

      const allDocuments = await http.get(API_ROUTES.REPORTS, params);
      dispatch.documentation.SET_PROP({ totalDocuments: allDocuments?.metadata?.pagination?.total, selectedReports: allDocuments.data });
    },
  }),
});

export default documentation;

/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */

import { ConnectedRouter } from 'connected-react-router';
import { Fragment, useMemo, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { Grid, Icon } from '@allfundsbank/react-ui-kit-portal';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import RoutesEnum from 'src/router/enums';

import RoutesSchema from 'src/router/schema';
import { WebContainer } from '@primary-components';
import Menu from 'src/components/Menu';
import FloatingSendMail from 'src/components/Primary/Modals/FloatingSendMail';
import Disclaimer from 'src/components/Primary/Disclaimer';
import SessionExpiredModal from 'src/components/Primary/Modals/SessionExpired';
import LogoutModal from 'src/components/Primary/Modals/Logout';
import useRestricted from 'src/hooks/useRestricted';
import { StyledBanner, StyledLabelNoRecords } from 'src/components/Primary/Templates';
import { themeHelper } from 'src/utils';

const ANALYTICS = 'UA-67878760-1';
ReactGA.initialize(ANALYTICS);

const getCurrentLocation = ({ router }) => router.location.pathname;
const getTrackerDetails = ({ auth }) => auth.client?.id;
const getIsSessionExpired = ({ auth }) => auth?.isSessionExpired;
const getLogoutModalState = ({ auth }) => auth?.showLogoutModal;
const getClientName = ({ auth }) => auth?.client?.metadata?.fullName;

const RouterLoader = ({ history }) => {
  const locationPath = useSelector(getCurrentLocation);
  const userId = useSelector(getTrackerDetails);
  const isExpiredSession = useSelector(getIsSessionExpired);
  const showLogoutModal = useSelector(getLogoutModalState);
  const clientName = useSelector(getClientName);
  const formattedClientName = (
    <p>
      You are viewing the client portal of
      <span> </span>
      <b>{clientName}</b>
      . Some functionalities may be disabled due to security reasons.
    </p>
  );
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const isRestricted = useRestricted();

  const menuExcludedPaths = [
    RoutesEnum.invitation.view,
    RoutesEnum.forgotPassword.view,
    RoutesEnum.resetPassword.view,
    RoutesEnum.signUp.view,
  ];

  const renderMenu = useMemo(() => !menuExcludedPaths.includes(locationPath), [locationPath]);

  const trackNavigation = () => {
    ReactGA.set({ userId });
    ReactGA.pageview(locationPath);
  };

  useEffect(() => {
    trackNavigation();
  });

  useEffect(() => {
    if (isExpiredSession) setShowSessionExpiredModal(true);
  }, [isExpiredSession]);

  if (showSessionExpiredModal) return <SessionExpiredModal show={setShowSessionExpiredModal} />;

  if (showLogoutModal) return <LogoutModal show={showLogoutModal} />;

  return (
    <ConnectedRouter history={history}>
      {isRestricted && (
        <StyledBanner fontSize={14} lineHeight={32}>
          <Icon marginright="12px" height="18px" width="18px" type="info-circle" fill={themeHelper.get('secondary')} />
          {formattedClientName}
        </StyledBanner>
      )}
      {renderMenu ? (
        <Menu />
      ) : (
        <></>
      )}
      <WebContainer className="main-content" disableMenu={!renderMenu}>
        <Grid>
          {RoutesSchema?.length && RoutesSchema.map(({ isExact, path, page, subRoutes, pathId }, index) => (
            <Fragment key={`${path}${index}`}>
              {subRoutes?.length && subRoutes.map((subRoute, indx) => (
                <Route
                  pathId={pathId}
                  exact={isExact}
                  component={subRoute.page}
                  path={`${path}${subRoute.path}`}
                  key={`${path}${subRoute.path}${index}${indx}`}
                />
              ))}
              <Route path={path} pathId={pathId} exact={isExact} component={page} key={`${path}${pathId}${index}`} />
            </Fragment>
          ))}
        </Grid>
        {menuExcludedPaths.includes(locationPath) ? '' : <Disclaimer />}
        {(!isRestricted && renderMenu) &&(
          <FloatingSendMail />
        )}
      </WebContainer>
    </ConnectedRouter>
  );
};

export default RouterLoader;

import { Button, Col, Label, Row, Modal } from '@allfundsbank/react-ui-kit-portal';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { themeHelper } from 'src/utils';

const StyledModal = styled(Modal)`
  width: 100%;
  min-width: 350px;
  max-width: 400px;
  z-index: 99;
`;

const StyledColContainer = styled(Col)`
  text-align: middle;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  background-color: ${themeHelper.get('white')};
  border-color: ${themeHelper.get('white')};
`;

const StyledRow = styled(Row)`
  justify-content: center !important;
`;

const LogoutModal = ({ show }) => {
  const dispatch = useDispatch();

  return (
    <StyledModal show={show} onClose={() => dispatch.auth.SET_PROP({ showLogoutModal: false })}>
      <StyledColContainer>
        <StyledRow textalign="center" margintop="md">
          <Label fontSize={18} fontWeight={800}>
            Are you sure you want to sign out?
          </Label>
        </StyledRow>
        <Row margintop="md" style={{ marginRight: 0 }}>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledButton maxWidth="144px" onClick={() => dispatch.auth.SET_PROP({ showLogoutModal: false })}>
              <Label fontSize={14} fontWeight={600} color={themeHelper.get('primary')} style={{ textDecoration: 'underline' }}>
                No
              </Label>
            </StyledButton>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <Button maxWidth="144px" onClick={() => dispatch.auth.logout(() => window.open('/', '_self'))}>
              <Label fontSize={14} fontWeight={600} color={themeHelper.get('white')}>
                Yes
              </Label>
            </Button>
          </Col>
        </Row>
      </StyledColContainer>
    </StyledModal>
  );
};

export default LogoutModal;

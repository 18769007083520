import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { loadFromStorage } from 'src/utils';
import { TKN_KEY } from 'src/enums';

const getIsAuthInState = ({ auth }) => auth?.isAuthenticated;

function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(loadFromStorage(TKN_KEY));
  const stateAuth = useSelector(getIsAuthInState);

  useEffect(() => {
    if (stateAuth) {
      setIsAuthenticated(stateAuth);
    }
  }, [stateAuth]);

  return isAuthenticated;
}

export default useAuth;

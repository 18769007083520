import styled, { css } from 'styled-components';
import { Icon } from '@allfundsbank/react-ui-kit-portal';
import { themeHelper } from 'src/utils';

const ItemRow = styled.div`
  width: 100%;
  color: ${themeHelper.get('primary')};
  margin-top: 22px;
  cursor: pointer;
  user-select: none;
  opacity: ${({ selected }) => (selected ? 1 : 0.4)};
  
  &:hover {
    ${({ selected }) => (selected ? '' : (
      css`
        & > i svg {
          fill: ${themeHelper.get('secondary')};
        }
        color: ${themeHelper.get('secondary')};
        opacity: 1 !important;
        & > span {
          font-family: ${themeHelper.get('font.secondary')} !important;
        }
      `
    ))};
  }
  & > span {
    font-family: ${({ selected }) => (selected ? themeHelper.get('font.secondary') : themeHelper.get('font.primary'))};
    font-size: 13px;
    line-height: 13px;
    margin-top: 3px;
  }
`;

const MenuItem = ({ selected, name, icon, onClick }) => (
  <ItemRow selected={selected} onClick={onClick}>
    <Icon marginright="12px" type={icon} />
    <span>
      {name}
    </span>
  </ItemRow>
);

export default MenuItem;

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Label } from '@allfundsbank/react-ui-kit-portal';
import { themeHelper } from 'src/utils';
import image from 'src/static/Login/maintenance.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const TitleLabel = styled(Label)`
  font-family: ${themeHelper.get('font.secondary')};
  font-weight: 350;
  font-size: 24px;
  line-height: 28.8px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const ContentText = styled(Label)`
  font-family: ${themeHelper.get('font.primary')};
  font-weight: 325;
  font-size: 14px;
  line-height: 16.8px;
  width: 80%;
  white-space: initial;
  text-align: center;
`;

const LinkText = styled.a`
  font-family: ${themeHelper.get('font.secondary')}; 
  text-decoration: underline;
  color:  ${themeHelper.get('secondary')};
`;


const MaintenancePage = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <img src={image} alt="Forgot password" />
      <TitleLabel>
        {t('MAINTENANCE_TITLE')}
      </TitleLabel>
      <ContentText>
        {t('MAINTENANCE_PHARAGRAPH_1')}
      </ContentText>
      <ContentText>
        {t('MAINTENANCE_PHARAGRAPH_2')} <LinkText href='mailto:clientservices@ccla.co.uk'>{t('MAINTENANCE_MAIL')}</LinkText>
      </ContentText>
    </Container>
  );
};

export default MaintenancePage;

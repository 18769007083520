import { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { ToastContainer } from 'react-toastify';
import IdleHandler from './components/Primary/IdleHandler';
import LoadingSpinnerCCLA from './components/Primary/LoadingSpinnerCCLA';
import GlobalStyles from 'src/globalStyles';
import useAuth from 'src/hooks/useAuth';
import Router from 'src/router';

const getTheme = ({ theme }) => theme?.webTheme;

const App = ({ history }) => {
  const isAuthenticated = useAuth();
  const theme = useSelector(getTheme);
  const dispatch = useDispatch();

  const handleIdleSession = async () => {
    dispatch.auth.logout(() => dispatch.auth.SET_PROP({ isSessionExpired: true }));
  };

  return (
    <>
      {!theme ? (
        ''
      ) : (
        <ThemeProvider theme={theme}>
          <Suspense fallback={<LoadingSpinnerCCLA />}>
            <Router history={history} />
            <GlobalStyles isAuthenticated={isAuthenticated} />
            <ToastContainer />
            <IdleHandler idleHandler={handleIdleSession} />
          </Suspense>
        </ThemeProvider>
      )}
    </>
  );
};

export default App;

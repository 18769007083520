/* eslint-disable no-console */
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Image, Textfield, Label, Button } from '@allfundsbank/react-ui-kit-portal';
import styled, { useTheme } from 'styled-components';
import { useEffect } from 'react';

import InputTokenPage from '../InputToken/InputTokenPage';
import MaintenancePage from './MaintenancePage';
import LoginContainer from 'src/components/Login/LoginContainer';
import logo from 'src/static/Login/ccla-logo.svg';
import { themeHelper } from 'src/utils';
import TermsAndConditions from 'src/components/Primary/Modals/TermsAndConditions';
import { StyledSpinner } from 'src/components/StyledComponents';

const LogoCol = styled(Col)`
  min-height: 60px !important;
`;
const LogoImage = styled(Image)`
  max-height: auto;
  max-width: 100%;
`;

const FlexContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const TextfieldContainer = styled.div`
  & > div {
    input {
      margin-top: 23px;
    }
    label {
      font-size: 14px;
      line-height: 16.8px;
      top: 18px;
    }
  }
`;

const getData = ({ loading, auth }) => ({
  error: auth?.error,
  showInputTokenPage: auth?.showInputTokenPage,
  verificationCode: auth?.verificationCode,
  showTermsAndConditions: auth?.showTermsAndConditions,
  signedAcceptance: auth?.client?.signedAcceptance || false,
  isLoading: loading.effects?.auth?.preLoginUser?.loading,
  isLoadingTokenPage: loading.effects?.auth?.loginUser?.loading,
  isLoadingResendSMS: loading.effects?.auth?.sendRefreshToken?.loading,
});

const LoginPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const query = new URLSearchParams(document.location.search);
  const { isLoading, error, verificationCode, showInputTokenPage, showTermsAndConditions, signedAcceptance, isLoadingTokenPage, isLoadingResendSMS } = useSelector(getData);
  const { register, handleSubmit, control, getValues } = useForm({ mode: 'onChange' });
  const { isValid, isSubmitted } = useFormState({ control });
  const preOnSubmit = (data) => dispatch.auth.preLoginUser(data);
  const { ref: usernameRef, ...userProps } = register('username', { required: true });
  const { ref: passwordRef, ...passProps } = register('password', { required: true });

  useEffect(() => {
    if (query.get('session')) {
      dispatch.auth.simulateLogin({ token: query.get('session') });
    }
  }, []);

  const onSubmit = (code) => {
    const data = getValues();
    dispatch.auth.loginUser({ verificationCode: code, ...data });
  };

  const refreshToken = () => {
    const data = getValues();
    dispatch.auth.sendRefreshToken(data);
  };

  if (window.appConfig.IS_MAINTENANCE === 'true' && !query.get('session')) return <MaintenancePage/>;

  if (showInputTokenPage) return <InputTokenPage refreshToken={refreshToken} theme={theme} onSubmit={onSubmit} verificationCode={verificationCode} isLoadingTokenPage={isLoadingTokenPage} isLoadingResendSMS={isLoadingResendSMS}  />;
  
  if (showTermsAndConditions && !signedAcceptance) return <TermsAndConditions />;

  return (
    <LoginContainer>
      <form onSubmit={handleSubmit(preOnSubmit)} autoComplete="off">
        <Row>
          <LogoCol xs={12} textalign="center">
            <LogoImage src={theme?.logo || logo} alt="ccla-logo" />
          </LogoCol>
          <Col xs={12} margintop="lg">
            <TextfieldContainer>
              <Textfield defaultValue="" placeholder={t('LOGIN_TEXTFIELD_USERNAME')} name="username" {...userProps} internalRef={usernameRef} />
            </TextfieldContainer>
          </Col>
          <Col xs={12} margintop="sm">
            <TextfieldContainer>
              <Textfield defaultValue="" placeholder={t('LOGIN_TEXTFIELD_PASSWORD')} type="password" {...passProps} internalRef={passwordRef} />
            </TextfieldContainer>
          </Col>
          {error && (
            <Col margintop="sm" textalign="center">
              <Label
                style={{ color: theme.negative || 'red' }}
              >
                {t('LOGIN_ERROR_BAD_CREDENTIALS')}
              </Label>
            </Col>
          )}
          {!isValid && isSubmitted && (
            <Col margintop="sm" textalign="center">
              <Label
                style={{ color: theme.negative || 'red' }}
              >
                {t('LOGIN_ERROR_FORM_INVALID')}
              </Label>
            </Col>
          )}
          <Col xs={12} margintop="lg" textalign="center" justifycontent="center">
            <Button type="submit" maxWidth="60%" disabled={isLoading} style={{ fontFamily: themeHelper.get('font.secondary') }}>
              <FlexContainerButton>
                <StyledSpinner color='white' isVisible={isLoading} />
                <Label fontSize={14} fontWeight={500} fontFamily={themeHelper.get('font.secondary')} color={themeHelper.get('white')}>
                  {t('LOGIN_BUTTON_LABEL')}
                </Label>
              </FlexContainerButton>
            </Button>
          </Col>
          <Col xs={12} margintop="lg" textalign="center">
            <Label underline cursor="pointer">
              <a href="/forgot-password" style={{ color: theme.black || 'black', opacity: '0.6' }}>
                {t('LOGIN_FORGOT_PASSWORD')}
              </a>
            </Label>
          </Col>
          <Col xs={12} margintop="sm" textalign="center">
            <Label cursor="pointer">
              {t('LOGIN_SIGN_UP_TEXT')}
              <a
                href="/sign-up"
                style={{
                  marginLeft: '5px',
                  color: theme.secondary,
                  textDecoration: 'underline',
                }}
              >
                {t('LOGIN_SIGN_UP_LINK')}
              </a>
            </Label>
          </Col>
        </Row>
      </form>
    </LoginContainer>
  );
};

export default LoginPage;

import isEqual from 'lodash.isequal';
import { memo, useEffect, useState } from 'react';
import RGL from 'react-grid-layout';
import styled from 'styled-components';

import { themeHelper } from 'src/utils';
import widthProvider from 'src/loaders/WidthProvider';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const StRGL = styled(RGL)`
  margin-left: -15px;
  margin-right: -15px;

  .react-grid-layout {
    z-index: 1;
  }

  .react-grid-item {
    z-index: 1;
  }

  .react-grid-item:hover {
    z-index: 10;
  }

  & > div.react-grid-item.react-grid-placeholder {
    background-color: ${themeHelper.get('primary')};
  }

  .react-resizable-handle.react-resizable-handle-se {
    opacity: 0;
    width: 24px;
    height: 24px;
    z-index: 10;
  }

  .react-grid-item.react-grid-placeholder {
    display: none;
  }
`;

const DragSystem = memo(({ layout, isLoadingLayout, setSystemLayout, cols = 2, children, ...props }) => {
  const [timerLoading, setTimerLoading] = useState(false);

  // This useEffect together with isLoadingLayout will avoid posterior PUT when setting layouts from bbdd
  useEffect(() => {
    if (isLoadingLayout) {
      setTimerLoading(true);
      setTimeout(() => {
          setTimerLoading(false);
        }, 1500);
    }
  }, [isLoadingLayout]);

  return (
    <StRGL
      autoSize
      layout={layout}
      className="layout"
      items={layout?.length}
      rowHeight={25}
      onLayoutChange={(currentLayout) => {
        if (timerLoading) return;
        if (isEqual(currentLayout, layout)) return;

        setSystemLayout(currentLayout);
      }}
      cols={cols}
      {...props}
    >
      {children}
    </StRGL>
  );
});

export default widthProvider(DragSystem);

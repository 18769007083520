import { createModel } from '@rematch/core';

import { HttpInstance } from 'src/utils';
import { API_ROUTES } from 'src/enums';

const http = new HttpInstance();

const invitation = createModel()({
  name: 'invitation',
  state: { isSendingData: false },
  reducers: {
    SET_PROP(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: (dispatch) => ({
    async createUser(data) {
      dispatch.invitation.SET_PROP({ isSendingData: true });

      const registerBody = JSON.parse(JSON.stringify(data));

      delete registerBody?.rePassword;

      try {
        await http.post(API_ROUTES.CREATE_INVITATION_CLIENT, { ...registerBody }, { forceCrash: true });
      } catch (e) {
        throw e?.response?.data?.message || '';
      } finally {
        dispatch.invitation.SET_PROP({ isSendingData: false });
      }
    },

    async resetUser(data) {
      dispatch.invitation.SET_PROP({ isSendingData: true });

      const registerBody = JSON.parse(JSON.stringify(data));

      delete registerBody?.rePassword;

      try {
        await http.post(API_ROUTES.CREATE_INVITATION_CLIENT_RESET, { ...registerBody }, { forceCrash: true });
      } catch (e) {
        throw e?.response?.data?.message || '';
      } finally {
        dispatch.invitation.SET_PROP({ isSendingData: false });
      }
    },
  }),
});

export default invitation;

/* eslint-disable react/no-did-update-set-state */
import SizeMe from 'react-sizeme';

const WidthProvider = (ComposedComponent) => ({ size, ...props }) => (
  <ComposedComponent width={size.width} height={size.height} {...props} />
);

const ProviderComposed = (ComposedComponent) => SizeMe({
  monitorWidth: true,
  monitorHeight: true,
})(WidthProvider(ComposedComponent));

export default ProviderComposed;

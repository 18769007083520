import styled, { css } from 'styled-components';
import { Icon, Label } from '@allfundsbank/react-ui-kit-portal';
import { themeHelper } from 'src/utils';

const ItemRow = styled.div`
  width: 100%;
  max-width: 150px;
  color: ${themeHelper.get('primary')};
  margin-top: 22px;
  cursor: pointer;
  user-select: none;
  display: flex;
  opacity: ${({ selected }) => (selected ? 1 : 0.4)};

  &:hover {
    ${({ selected }) => (selected ? '' : (
      css`opacity: 1 !important;
      & > i svg {
        fill: ${themeHelper.get('secondary')};
      }
      & > div {
        color: ${themeHelper.get('secondary')} !important;
        font-family: ${themeHelper.get('font.secondary')} !important;
      }`
    ))};
  }
`;
const StyledLabel = styled(Label)`
  text-overflow: ellipsis;
  white-space: break-spaces;
  overflow: hidden;
  font-family: ${({ selected }) => (selected ? themeHelper.get('font.secondary') : themeHelper.get('font.primary'))} !important;
  font-size: 13px;
  line-height: 13px;
  margin-top: 3px;
`;

const FooterItem = ({ name, icon, iconComponent, onClick, selected }) => (
  <ItemRow className="footeritem" onClick={onClick} selected={selected}>
    {!iconComponent ? <Icon marginright="12px" type={icon} /> : iconComponent}
    <StyledLabel selected={selected}>{name}</StyledLabel>
  </ItemRow>
);

export default FooterItem;

const formatBenchmarkOptions = (benchmarks) => {
  if (!benchmarks?.length) return [];

  return benchmarks.map((item) => ({
    label: item?.name,
    key: item?.id,
  }));
};

const BENCHMARKS = { formatBenchmarkOptions };

export default BENCHMARKS;

const PERFORMANCE_TIME = Object.freeze({
  INCEPTION: 'inception',
  LAST_COMPOSITION: 'lastComposition',
  M1: '1M',
  YTD: 'YTD',
  Y1: '1Y',
  Y3: '3Y',
  Y5: '5Y',
  Y10: '10Y',
  PERIOD: 'period',
});

const DISTRIBUTION_TYPES = Object.freeze([
  'assetclasses',
  'instrumenttypes',
  'countries',
  'sectors',
  'currencies',
  'topten',
]);

const DISTRIBUTION_TYPES_INSTRUMENTS = Object.freeze([
  'instrumenttypes',
  'countries',
  'currencies',
]);

const INSTRUMENTS_BENCHMARKS = Object.freeze({
  EONABERA: 'EONABERA',
  MSDUWI: 'MSDUWI',
});

const ANALYSIS = {
  PERFORMANCE_TIME,
  DISTRIBUTION_TYPES,
  DISTRIBUTION_TYPES_INSTRUMENTS,
  INSTRUMENTS_BENCHMARKS,
};

export default ANALYSIS;

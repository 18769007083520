import orderBy from 'lodash.orderby';
import MENU from 'src/helpers/menu';
import DASHBOARD from 'src/helpers/dashboard';
import PROFILE from 'src/helpers/profile';
import BENCHMARKS from 'src/helpers/benchmarks';
import DOCUMENTATION from 'src/helpers/documentation';
import SCREENER from 'src/helpers/screener';
import PERSONS from 'src/helpers/persons';
import ACCOUNTS from 'src/helpers/accounts';

const SORT_KEYS = {
  NAME: 'name',
  TACLIENT: 'taclient',
  SHARES: 'shares',
  CONTRIBUTIONS: 'contributions',
  VALUE: 'value',
  PROFITLOSS: 'profitloss',
  WEIGHT: 'weight',
};
const SORT = {
  ASC: 'asc',
  DSC: 'desc',
};
const handleSortPositions = ({ items, sort, sortKey }) => {
  if (!items?.length) return [];

  switch (sortKey) {
    case SORT_KEYS.NAME:
      return orderBy(items, ['accountData.name'], [sort]);
    case SORT_KEYS.TACLIENT:
      return orderBy(items, ['accountData.metadata.subdistributorName'], [sort]);
    case SORT_KEYS.SHARES:
      return orderBy(items, ['quantity'], [sort]);
    case SORT_KEYS.CONTRIBUTIONS:
      return orderBy(items, ['contributions'], [sort]);
    case SORT_KEYS.VALUE:
      return orderBy(items, ['amount'], [sort]);
    case SORT_KEYS.PROFITLOSS:
      return orderBy(items, ['profitLoss'], [sort]);
    case SORT_KEYS.WEIGHT:
      return orderBy(items, ['weight'], [sort]);
    default:
      return items;
  }
};
function getPositionName(item, t) {
  if (item?.level === 'total') return t('POSITIONS_TABLE_TOTAL');
  return item?.level ? item?.relation?.name || item?.relationId : item?.instrument?.name || t('POSITION_PENDING');
}

const POSITIONS = {
  SORT, SORT_KEYS, handleSortPositions, getPositionName,
};

export { MENU, DASHBOARD, PROFILE, BENCHMARKS, DOCUMENTATION, SCREENER, PERSONS, ACCOUNTS, POSITIONS };

class GraphHelper {
  static marginGraph = 0.02;

  static getDomain(data, attributeToComprobate = []) {
    if (!data || !data.length) return [];
    if (!attributeToComprobate || !attributeToComprobate.length) return [];

    let minValue = data[0][attributeToComprobate[0]];
    let maxValue = data[0][attributeToComprobate[0]];
    for (let i = 0; i < data.length; i += 1) {
      const element = data[i];

      for (let j = 0; j < attributeToComprobate.length; j += 1) {
        const attribute = attributeToComprobate[j];
        minValue = Math.min(element[attribute], minValue);
        maxValue = Math.max(element[attribute], maxValue);
      }
    }

    return [minValue - this.marginGraph, maxValue + this.marginGraph];
  }

  static calculateTicks = (domain, interval = 8) => {
    const [minValue, maxValue] = domain;
    const range = maxValue - minValue;
    const distance = range / interval;
    const ticks = [];

    if (minValue < 0 && maxValue > 0) {
      let auxI = 1;
      ticks.push(minValue);
      ticks.push(0);
      ticks.push(maxValue);

      while (Math.abs(minValue) > (distance * auxI) || maxValue > (distance * auxI)) {
        if (Math.abs(minValue) > distance * auxI) {
          ticks.push(-(distance * auxI));
        }
        if (maxValue > distance * auxI) {
          ticks.push(distance * auxI);
        }
        auxI += 1;
      }
    } else {
      ticks.push(minValue);

      for (let i = 0; i < interval; i += 1) {
        ticks.push(ticks[i] + distance);
      }

      ticks.push(minValue - distance);
    }

    return ticks.sort((a, b) => a - b);
  };

  static domainContainNegativeValues(data, attributeToComprobate = []) {
    if (!data) return false;

    for (const element of data) {
      for (const attribute of attributeToComprobate) {
        if (element[attribute] < 0) {
          return true;
        }
      }
    }

    return false;
  }

  static calculateTicksScatter(domain, intervals = 7) {
    const [dataMin, dataMax] = domain;
    const numberDataMin = Number(dataMin);
    const numberDataMax = Number(dataMax);
    if (isNaN(numberDataMin) || isNaN(numberDataMax)) {
      return [];
    }

    const hasNegativeValues = numberDataMin < 0;
    const mainMax = Math.abs(numberDataMax) > Math.abs(numberDataMin) ? Math.abs(numberDataMax) : Math.abs(numberDataMin);
    const interval = mainMax / Math.trunc(intervals / (hasNegativeValues ? 2 : 1));

    const ticks = [numberDataMin];
    for (let i = 1; i < intervals; i += 1) {
      if (ticks[i - 1] < 0 && numberDataMin + i * interval > 0) {
        ticks.push(0);
      }

      ticks.push(numberDataMin + i * interval);
    }

    ticks.push(numberDataMin + intervals * interval);
    return ticks;
  }
}

export default GraphHelper;

import SIZES from './sizes';

const theme = {
  backgroundLogin: '#ffffff',
  background: '#F8F8F8',
  secondaryBackground: '#FBFBFB',
  primary: '#5d6164',
  secondary: '#D10074',
  secondaryTransparent: '#D100741A',
  tertiary: '#D50032',
  quaternary: '#54CC76',
  quaternaryTransparent: '#54CC761A',
  quinary: '#D100D5',
  quinaryTransparent: '#D100D51A',
  sixth: '#E38800',
  seventh: '#394AE7',
  white: '#ffffff',
  black: '#000000',
  border: '#E5E6E8',
  positive: '#54CC76',
  negative: '#FB7373',
  neutral: '#efbb61',
  font: { primary: 'Gotham', secondary: 'Gotham Medium' },
  fontWeight: {
    100: '100',
    200: '200',
    300: '300',
    400: '400',
    500: '500',
    600: '600',
    700: '700',
    800: '800',
    900: '900',
  },
  opacity: {
    '01': '1A',
    '02': '33',
    '03': '4D',
    '04': '66',
    '05': '80',
    '06': '99',
    '07': 'B3',
    '08': 'CC',
    '09': 'E6',
    1: 'FF',
  },
  sizes: { ...SIZES },
};

export default theme;

import { useTranslation } from 'react-i18next';
import { Row, Col, Image, Label, Button } from '@allfundsbank/react-ui-kit-portal';
import 'react-toastify/dist/ReactToastify.css';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import LoginContainer from 'src/components/Login/LoginContainer';
import logo from 'src/static/Login/verificationToken.svg';
import ReactCodeInput from 'src/components/Primary/ReactCodeInput/ReactCodeInput';
import { themeHelper } from 'src/utils';
import { StyledSpinner } from 'src/components/StyledComponents';

const LogoCol = styled(Col)`
  min-height: 60px !important;
`;
const LogoImage = styled(Image)`
  max-height: auto;
  max-width: 100%;
`;

const DigitLabel = styled(Label)`
  font-family: ${themeHelper.get('font.secondary')};
  font-weight: 400;
`;
const StyledReactCodeInput = styled(ReactCodeInput)`
 .react-code-input::-webkit-outer-spin-button,
 .react-code-input::-webkit-inner-spin-button {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  .react-code-input .react-code-inner-input {
    height: 40px;
    width: 40px;
    margin: 4px;
    background-color: #F8F8F8 ;
    border: none;
    border-radius: 5px;
    text-align: center;
  }
`;

const FlexContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const getError = ({ auth }) => auth?.error;

const InputTokenPage = ({ onSubmit, onClose, verificationCode, theme, refreshToken, isLoadingTokenPage, isLoadingResendSMS }) => {

  const { t } = useTranslation();
  const [innerCode, setInnerCode] = useState('');

  const error = useSelector(getError);

  useEffect(() => {
    if (error) setInnerCode('');
  }, [error]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (innerCode && innerCode.length === 6) onSubmit(innerCode);
  };
  const resendSMS = () => {
    if(isLoadingResendSMS) return;
    refreshToken();
  };

  return (
    <LoginContainer>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Row>
            <LogoCol xs={12} textalign="center">
              <LogoImage src={logo} alt="ccla-logo" />
            </LogoCol>
            <Col xs={12} margintop="lg" textalign="center">
              <DigitLabel fontSize={20} lineHeight="30">
                {t('TOKEN_TITLE_LABEL')}
              </DigitLabel>
              <Label
                fontSize={10}
                style={{
                  whiteSpace: 'unset',
                  overflow: 'unset',
                  textOverflow: 'unset',
                }}
              >
                {t('TOKEN_PARAGRAPH_LABEL')}
              </Label>
              <Label fontSize={10}>
                {verificationCode}
              </Label>
              <StyledReactCodeInput onChange={(value) => setInnerCode(value)} type="number" fields={6} />
            </Col>
            {error && (
            <Col margintop="sm" textalign="center">
              <Label
                style={{ color: theme.negative || 'red' }}
              >
                {t('TOKEN_ERROR_BAD_CREDENTIALS')}
              </Label>
            </Col>
          )}
            <Col xs={12} margintop="lg" textalign="center">
              <FlexContainerButton>
                <StyledSpinner color={theme.secondary} isVisible={isLoadingResendSMS} />
                <Label onClick={resendSMS} underline cursor="pointer">
                  <p
                    style={{
                    textDecoration: 'underline', color: theme.secondary, opacity: '0.6',
                    }}
                  >
                    {t('TOKEN_RESEND_CODE')}
                  </p>
                </Label>
              </FlexContainerButton>
            </Col>
            <Col xs={12} margintop="lg" textalign="center" justifycontent="center">
              <Button type="submit" maxWidth="60%" disabled={isLoadingTokenPage}>
                <FlexContainerButton>
                  <StyledSpinner color='white' isVisible={isLoadingTokenPage} />
                  <Label fontSize={14} fontWeight={500} fontFamily={themeHelper.get('font.secondary')} color={themeHelper.get('white')}>
                    {t('TOKEN_LOGIN_BUTTON_LABEL')}
                  </Label>
                  </FlexContainerButton>
              </Button>
            </Col>
          </Row>
        </form>
      </LoginContainer>
  );
};
export default InputTokenPage;

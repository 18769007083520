const API_ROUTES = Object.freeze({
  PRE_AUTH: '/public/user-auth/portal-user/login/requests',
  SIGN_UP: '/public/sign-up',
  REFRESH_TOKEN: '/public/user-auth/portal-user/refresh-token',
  AUTH: '/public/user-auth/portal-user/login',
  SIMULATE_AUTH: '/public/user-auth/portal-user/login/simulate',
  PUBLIC_AUTH_REQUEST_PASSWORD: '/public/user-auth/portal-user/reset-password/requests',
  PUBLIC_AUTH_REQUEST_PASSWORD_2FA: '/public/user-auth/portal-user/reset-password/requests/2fa',
  PUBLIC_AUTH_REQUEST_RESET_PASSWORD: '/public/user-auth/portal-user/reset-password',
  AUTH_REQUEST_CHANGE_PASSWORD: '/user-auth/portal-user/change-password/requests',
  AUTH_CHANGE_PASSWORD: '/user-auth/portal-user/change-password',
  LOGOUT: '/user-auth/portal-user/logout',
  REPORTS: '/document-repository/search',
  REPORTS_FNZ: '/document-repository/fnz/search',
  REPORTS_TYPES_FNZ: '/document-repository/fnz/types',
  DOWNLOAD_FNZ_REPORT: '/document-repository/fnz/document',
  ACCOUNTS: '/contracts',
  CONTACT: '/contact',
  DOCUMENT_REPOSITORY: '/document-repository',
  DOCUMENT_REPOSITORY_ORGANISATION_COUNT: '/document-repository/organisations',
  ORGANISATIONS: '/organisations/summary',
  PRE_CHANGE_PASSWORD: '/portal-user/change-password/requests',
  CHANGE_PASSWORD: '/portal-user/change-password',
  TRANSACTIONS: '/transactions',
  TRANSACTIONS_TYPES: '/transactions/types',
  CASH_ENTRIES_SEARCH: '/distributions/cash-entries/search',
  REINVESTMENTS_SEARCH: '/distributions/reinvestments/search',
  POSITIONS: '/positions',
  POSITIONS_SERIE: '/positions/serie',
  POSITIONS_AGGREGATE: '/positions/aggregate',
  TACLIENT: '/depositaries',
  SUMMARY: '/analysis/summary',
  PERSONS_ORGANIZATION: '/persons/organisations',
  PERSONS_DETAIL: '/persons',
  USERS: '/users',
  PRESETS: '/presets',
  FAQS: '/faqs',
  DOCUMENT_TYPES: '/document-repository/document-types',
  USER_SETTINGS: '/user-settings',
  /// TODO: REMOVE UNUSED ROUTES
  COUNTRIES: '/countries/search',
  CURRENCIES: '/currencies/search',
  CONTRACTS: '/analysis/global-positions/contracts',
  BENCHMARKS: '/benchmarks',
  PERFORMANCE: '/analysis/performance/transactions',
  PERFORMANCE_INDEX: '/analysis/performance/backtesting',
  PERFORMANCE_BACKTESTING: '/analysis/performance/compositions',
  PDF_BACKGROUND: '/pdfs-background/contracts/pdf/background',
  PDF_CONTRACTS: 'pdfs-background/contracts',
  PDF_SEARCH: '/pdfs-background/search',
  DISTRIBUTIONS: '/analysis/distributions/multi',
  RELATIONS: '/analysis/relations',
  INSTRUMENT_PEER_GROUP: '/relations/peergroup/instruments',
  INSTRUMENT_PEER_GROUP_BENCHMARK: '/relations/masterdatas-benchmarks/peergroup',
  RELATIONS_ASSET_CLASS: '/relations/masterdatas/assetclass/search',
  RELATIONS_SUB_ASSET_CLASS: '/relations/masterdatas/subassetclass/search',
  RATIOS: '/analysis/risks/expost/ratios/series',
  RISK_RATIOS_ITEMS: '/analysis/risks/expost/ratios/backtesting',
  CLIENT: '/clients',
  INSTRUMENTS: '/instruments',
  INSTRUMENTS_COMPLEX: '/instruments/complex/search',
  // ENTITIES_ANALISYS: '/entities/analysis',
  CREATE_INVITATION_CLIENT: '/portal-clients',
  CREATE_INVITATION_CLIENT_RESET: '/portal-clients/reset',
  PRICES: '/prices',
  EXCEL_POSITIONS: '/xlsx/positions',
  EXCEL_TRANSACTIONS: '/xlsx/transactions',
  EXCEL_CASH_ENTRIES: '/xlsx/cash-entries',
  ORGANISATION: '/organisations',
});

export default API_ROUTES;

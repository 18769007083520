/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Col, Icon, Label, Modal, Row, Select, Td, Th, Tr } from '@allfundsbank/react-ui-kit-portal';
import styled from 'styled-components';
import { themeHelper } from 'src/utils';

const ZeroRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`;
const ZeroCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`;

const StyledInnerTh = styled(Th)`
  position: relative;
  opacity: ${({ sortActive }) => (sortActive ? 1 : 0.5)};
  font-family: ${({ sortActive }) => (sortActive ? themeHelper.get('font.secondary') : themeHelper.get('font.primary'))};
  padding: 0;
  font-size: 12px; 
  line-height: 14.4px;
  & > i {
    position: absolute;
    padding-top: 0;
    transform: ${({ sortActive, sort }) => {
      if (!sortActive) return '';
      const isDesc = sort === 'DSC' || sort === 'desc';
      return isDesc ? 'rotate(180deg)' : 'rotate(0deg)';
    }} !important;
  }
`;

const TableTh = ({ size, children, onClick, ...props }) => (
  <StyledInnerTh
    style={{ width: size, minWidth: size }}
    {...props}
    onClick={onClick}
  >
    <span onClick={onClick} style={{ cursor: onClick ? 'pointer' : '' }}>
      {children}
    </span>
  </StyledInnerTh>
);

const TableTd = styled(Td)`
  padding: 0px;
  white-space: unset;
`;
const StyledLabelNoRecords = styled(Label)`
  opacity: 0.5;
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
`;

const StyledBanner = styled(Label)`
  text-align: center;
  width: 100%;
  height: 50px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 21;
  position: sticky;
  top: 0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
  color: ${themeHelper.get('secondary')};
  @media screen and (max-width: 768px){
    font-size: 9px;
    z-index: 101;
  }
`;

const RowNoWrap = styled(Row)`
  flex-flow: unset !important;
`;

const TwoLineLabel = styled(Label)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines || 2};
  -webkit-box-orient: vertical;
  white-space: break-spaces;
`;

const FloatingMailIcon = styled(Icon)`
  position: fixed;
  width: 48px;
  height: 48px;
  bottom: ${({ bottomPosition }) => bottomPosition}px;
  right: 24px;
  background-color: ${themeHelper.get('secondary')};
  color ${themeHelper.get('white')};
  border-radius: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 41;
  svg {
    fill: ${themeHelper.get('white')};
    width: 24px;
  }
`;

const ForcedIcon = styled(Icon)`
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  & > svg {
    width: ${({ width }) => width || 'auto'} !important;
    height: ${({ height }) => height || 'auto'} !important;
  }
`;

const StyledSelect = styled(Select)`
  min-width: 100%;
  min-height: 48px;
  > div {
    background-color: ${({ backgroundColor }) => (backgroundColor || themeHelper.get('white'))};
  }
  & > i svg{
    width: 11px !important;
    height: 7px !important;
  }
`;

const TableTr = styled(Tr)`
  &:hover {
    background-color: #FFFFFF !important;
  }
`;

const StyledModalWrapper = styled.div`
  .backdrop {
    position: fixed;
    height: 100%;
  }
`;
const StyledModalItem = styled(Modal)`
  & > div {
    max-width: 100%
  }
  @media only screen and (min-width: 360px) and (max-width: 575px) {
    width: 90%;
    max-width: 90%;
    .backdrop {
      position: fixed;
      height: 100%;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    min-width: 95%;
  }
  @media only screen and (min-width: 768px){
    & > i#modalCross {
      width: 10px;
      margin-right: 20px;
      svg {
        width: 10px !important;
        margin-top: 16px;
      }
    }
  }
`;
// This one will not overextend and autocentered in middle
const FixedModal = ({ children, ...props }) => (
  <StyledModalWrapper>
    <StyledModalItem {...props}>{children}</StyledModalItem>
  </StyledModalWrapper>
);

export {
  TableTh,
  TableTd,
  TableTr,
  ZeroRow,
  ZeroCol,
  RowNoWrap,
  StyledLabelNoRecords,
  TwoLineLabel,
  FloatingMailIcon,
  ForcedIcon,
  StyledSelect,
  FixedModal,
  StyledBanner,
};

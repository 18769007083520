import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Row, Col, Icon } from '@allfundsbank/react-ui-kit-portal';
import { useHistory, useLocation } from 'react-router-dom';
import { themeHelper } from 'src/utils';
import { MENU } from 'src/helpers';
import MenuItem from 'src/components/Menu/MenuItem';
import FooterItem from 'src/components/Menu/FooterItem';
import logo from 'src/static/Login/ccla-logo.svg';
import useMobileDetection from 'src/hooks/useMobileDetection';
import useRestricted from 'src/hooks/useRestricted';

const { getMenuItems } = MENU;

const StyledMenu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 186px;
  background: ${themeHelper.get('backgroundLogin')};
  padding: 24px;
  padding-top: ${({ isRestricted }) => (isRestricted ? '70px' : '24px')};
  z-index: 20;
`;
const StyledImage = styled(Image)`
  max-width: 138px;
  margin-bottom: 18px;
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  margin-bottom: 24px;
  max-width: 138px;

  @media (max-height: 450px) {
    position: relative;
    margin-top: 18px;
  }
`;
const Divider = styled.div`
  height: 1px;
  opacity: 0.1;
  background: ${themeHelper.get('primary')};
  width: 138px;
  margin-left: 15px;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
  width: 10px;
  display: none;
`;
const StyledHambIcon = styled(Icon)`
  cursor: pointer;
  width: 44px !important;
  height: 44px;
  display: block !important;
  & > svg {
    margin: 15px 10px;
  }
  @media only screen and (min-width: 360px) and (max-width: 575px) {
    margin-top: 52px;
    margin-left: 8px;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    margin-top: 26px;
    margin-left: 30px;
  }
`;
const RowLogoContainer = styled(Row)`
  align-items: center !important;
  justify-content: space-between !important;
  margin: 0px 0 ${({ width }) => (width < 450 ? '120px' : '0px')} 0px !important;
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    margin-bottom: 55px !important;
  }
`;

const getClient = ({ auth }) => auth?.client;
const getOrganisations = ({ dashboard }) => ({
  selectedOrganisationId: dashboard?.selectedOrganisationId,
  organisations: dashboard?.organisations,
  noOrganisations: dashboard?.noOrganisations,
});

const Menu = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector(getClient);
  const { selectedOrganisationId, organisations, noOrganisations } = useSelector(getOrganisations);
  const location = useLocation();
  const history = useHistory();
  const [, width] = useMobileDetection();
  const [menuOpen, setmenuOpen] = useState(false);
  const { personalInfo } = client || {};
  const fullName = personalInfo?.metadata?.fullName;
  const { items, footer } = useMemo(() => getMenuItems(t, history, location, fullName, dispatch), [i18n?.language, location, fullName]);
  const isRestricted = useRestricted();

  const isAdmin = organisations?.find((item) => item?.organisationId === selectedOrganisationId)?.admin || false;

  return (
    <>
      <div style={{ marginBottom: '24px' }}>
        <StyledHambIcon className="list-button" type="hamburger" onClick={() => setmenuOpen(true)} />
      </div>
      <StyledMenu isRestricted={isRestricted} className={`sidebar ${menuOpen ? 'active' : ''}`}>
        <RowLogoContainer width={width}>
          <StyledImage src={logo} className="logo" alt="allfunds-logo-menu" />
          <StyledIcon className="close-menu" type="cross" onClick={() => setmenuOpen(false)} />
        </RowLogoContainer>
        <Row>
          {items?.length ? items.map((item, index) => {
            if (item?.documents && noOrganisations) return '';
            if (item?.admin && !isAdmin) return '';
            return (
              <Col xs={12} key={`menu-items-${item.name}-${index}`}>
                <MenuItem
                  name={item.name}
                  selected={item.selected}
                  icon={item.icon}
                  onClick={() => {
                setmenuOpen(false);
                item.onClick();
              }}
                />
              </Col>
            );
          }) : ''}
        </Row>
        <Footer>
          <Row style={{ flexFlow: 'column wrap' }}>
            <Divider />
            {footer?.length ? footer.map((foot, fIndex) => (
              <Col xs={12} key={`menu-footer-${foot.name}-${fIndex}`}>
                <FooterItem
                  name={foot.name}
                  icon={foot.icon}
                  iconComponent={foot?.iconComponent}
                  selected={foot.selected}
                  onClick={() => {
                  setmenuOpen(false);
                  foot.onClick();
                }}
                />
              </Col>
            )) : ''}
          </Row>
        </Footer>
      </StyledMenu>
    </>
  );
};

export default Menu;

import { useState } from 'react';

import { loadFromStorage } from 'src/utils';
import { RESTRICTED_KEY } from 'src/enums';

function useRestricted() {
  const [isRestricted] = useState(loadFromStorage(RESTRICTED_KEY));

  return isRestricted;
}

export default useRestricted;

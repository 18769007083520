import { Button, Col, Label, Row, Modal } from '@allfundsbank/react-ui-kit-portal';
import styled from 'styled-components';
import { themeHelper } from 'src/utils';

const StyledModal = styled(Modal)`
  z-index: 99;
`;

const StyledColContainer = styled(Col)`
  text-align: middle;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledRow = styled(Row)`
  justify-content: center !important;
`;

const SessionExpiredModal = ({ show }) => (
  <StyledModal show={show} onClose={() => window.open('/', '_self')}>
    <StyledColContainer>
      <StyledRow textalign="center" margintop="md">
        <Label fontSize={18} fontWeight={800}>
          Your session has expired
        </Label>
      </StyledRow>
      <StyledRow margintop="md">
        <Row>
          <Label fontSize={14} fontWeight={400}>
            Please log in again
          </Label>
        </Row>
      </StyledRow>
      <Row margintop="md">
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <Button maxWidth="144px" onClick={() => window.open('/', '_self')}>
            <Label fontSize={14} fontWeight={600} color={themeHelper.get('white')}>
              Accept
            </Label>
          </Button>
        </Col>
      </Row>
    </StyledColContainer>
  </StyledModal>
);

export default SessionExpiredModal;

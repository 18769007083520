import { getI18n } from 'react-i18next';

// const FOUR_DIGITS = { minimumFractionDigits: 4, maximumFractionDigits: 4 };
const TWO_DIGITS = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
const LOCALE = {
  es: 'ca-CA',
  en: 'en-US',
  'es-ES': 'ca-CA',
  'en-EN': 'en-US',
};

const number = (value) => {
  const { language } = getI18n();

  return Intl.NumberFormat(LOCALE[language]).format(value);
};

const shares = (value) => {
  const { language } = getI18n();

  return Intl.NumberFormat(LOCALE[language], { ...TWO_DIGITS }).format(value);
};

const price = (value) => {
  const { language } = getI18n();

  return Intl.NumberFormat(LOCALE[language], { ...TWO_DIGITS }).format(value);
};

const percent = (value) => {
  const { language } = getI18n();

  return `${Intl.NumberFormat(LOCALE[language], { ...TWO_DIGITS }).format(value)}%`;
};

const percentBase1 = (value) => {
  const { language } = getI18n();

  return `${Intl.NumberFormat(LOCALE[language], { ...TWO_DIGITS }).format(value * 100)}%`;
};

const weight = (value) => {
  const { language } = getI18n();

  return `${Intl.NumberFormat(LOCALE[language], { ...TWO_DIGITS }).format(value * 100)}%`;
};

const currency = (value, currencyValue = 'GBP') => {
  const { language } = getI18n();

  return Intl.NumberFormat(LOCALE[language], {
    style: 'currency',
    currency: currencyValue,
    ...TWO_DIGITS,
  }).format(value);
};

const currencyShort = (value, currencyValue = 'GBP') => {
  const { language } = getI18n();

  return Intl.NumberFormat(LOCALE[language], {
    notation: 'compact',
    compactDisplay: 'short',
    style: 'currency',
    currency: currencyValue,
    ...TWO_DIGITS,
  }).format(value);
};

const formatter = {
  number,
  percent,
  percentBase1,
  weight,
  currency,
  currencyShort,
  shares,
  price,
};

export default formatter;

/* eslint-disable consistent-return */
import { addDays } from '@allfundsbank/np-date';
import { useEffect } from 'react';
import { SESSION_EXPIRED } from 'src/enums';
import useAuth from 'src/hooks/useAuth';
import useRestricted from 'src/hooks/useRestricted';
import { loadFromStorage, saveToStorage } from 'src/utils';

const { ENV } = window.appConfig;
const DEVELOPMENT = 'development';

const IdleHandler = ({ idleHandler, idleTimeOut = 900000, idleCheckTime = 10000 }) => {
  const events = ['load', 'mousedown', 'mousemove', 'keypress'];
  const isAuthenticated = useAuth();
  const isRestricted = useRestricted();

  const setIdleTimer = () => {
    const now = new Date();
    const item = now.getTime() + idleTimeOut;
    saveToStorage(SESSION_EXPIRED, JSON.stringify(item), addDays(1, new Date()), isRestricted);
  };

  const testIdle = async () => {
    const idleExpirer = loadFromStorage(SESSION_EXPIRED);
    if (!idleExpirer) return;

    const now = new Date();

    if (now.getTime() > idleExpirer) await idleHandler();
  };

  useEffect(() => {
    setIdleTimer();
    if (!isAuthenticated || ENV === DEVELOPMENT) return;

    events.forEach((name) => {
      window.addEventListener(name, setIdleTimer);
    });

    setInterval(testIdle, idleCheckTime);

    return () => {
      events.forEach((name) => {
        window.removeEventListener(name, setIdleTimer);
      });
    };
  }, [isAuthenticated]);

  return null;
};

export default IdleHandler;

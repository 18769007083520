import { Button, Checkbox, Col, Label, Modal, Row } from '@allfundsbank/react-ui-kit-portal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { TwoLineLabel } from '../Templates';
import { themeHelper } from 'src/utils';

import { dropStorage } from 'src/utils/local-storage';
import useMobileDetection from 'src/hooks/useMobileDetection';
import useRestricted from 'src/hooks/useRestricted';

const StyledModal = styled(Modal)`
  min-width: 70%;
  position: fixed;
  overflow: hidden;
  max-width: 100%;
  & > i {
    display: none;
  }
  & > div {
    display: flex;
    max-width: 100%;
  }
  & > div > div {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 3rem !important;
    @media only screen and (min-width: 360px) and (max-width: 768px) {
      padding: 1.5rem !important;
    }
  }
  @media only screen and (min-width: 360px) and (max-width: 575px) {
    width: 90%;
    max-width: 90%;
    height: -webkit-fill-available;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    width: 90%;
    max-width: 90%;
    height: -webkit-fill-available;
    max-height: 450px;
  }
`;
const BorderWrapper = styled.div`
  border: 1px solid ${themeHelper.get('primary')};
  border-radius: 10px;
  min-height: 245px;
  margin: 1px;
  position: relative;

  @media only screen and (min-width: 360px) and (max-width: 768px) {
    flex: 1;
  }
`;
const BodyLabel = styled(Label)`
  white-space: unset;
  text-align: left;
  padding: 24px;
  font-size: 12px;
`;
const ScrollDiv = styled.div`
  width: 100%;
  max-height: 243px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (min-width: 360px) and (max-width: 575px) {
    height: -webkit-fill-available;
    max-height: unset;
    position: absolute;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    height: -webkit-fill-available;
    max-height: unset;
    position: absolute;
  }
`;
const RowCheckboxContainer = styled(Row)`
  justify-content: center !important;
  margin: 0;
  flex-wrap: nowrap !important;
  max-width: 100%;
  margin-left: 5px !important;
  margin-right: 10px !important;
`;
const StyledCheckbox = styled(Checkbox)`
  margin-left: 15px;
  & > input {
    background-color: ${themeHelper.get('secondary')};
  }
`;
const ButtonAccept = styled(Button)`
  margin-left: 12px;
  font-size: 14px;
  max-width: 189px;
  font-family: ${themeHelper.get('font.secondary')};
  @media only screen and (min-width: 360px) and (max-width: 575px) {
    width: unset;
    padding: 0 38px 0 38px;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    width: calc(50% - 12px);
    padding: 0 38px 0 38px;
    max-width: unset;
  }
`;
const ButtonDecline = styled(Button)`
  margin-right: 12px;
  font-size: 14px;
  max-width: 189px;
  font-family: ${themeHelper.get('font.secondary')};
  background-color: white;
  color: ${themeHelper.get('secondary')};
  @media only screen and (min-width: 360px) and (max-width: 575px) {
    width: unset;
    padding: 0 38px 0 38px;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    width: calc(50% - 12px);
    padding: 0 38px 0 38px;
    max-width: unset;
  }
`;
const Bold = styled.span`
  font-size: ${({ fontSize }) => fontSize || 12}px;
  font-family: ${themeHelper.get('font.secondary')};
`;
const Underline = styled.span`
  font-size: ${({ fontSize }) => fontSize || 12}px;
  text-decoration: underline;
`;

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const isRestricted = useRestricted();
  const [,, media] = useMobileDetection();
  const [show, setShow] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = async () => {
    await dispatch.auth.acceptTermsAndConditions();
    setShow(false);
  };

  const handleDecline = () => {
    dropStorage(isRestricted);
    window.open('/', '_self');
  };

  const onClose = () => {
    setShow(true);
  };

  return (
    <StyledModal show={show} onClose={onClose}>
        <Row>
          <Col xs={12} marginbottom={media?.isPhone ? 'md' : 'lg'}>
            <TwoLineLabel fontSize={18} textalign="center" fontFamily={themeHelper.get('font.secondary')} lineHeight={24}>
              CCLA Digital Portal Terms and Conditions
            </TwoLineLabel>
          </Col>
        </Row>
        <BorderWrapper>
          <ScrollDiv>
            <Col xs={12}>
              <BodyLabel textalign="center">
                <p><Bold fontSize={14}>CCLA Client Portal</Bold></p>
                <p><Underline fontSize={14}>Terms and Conditions of Use</Underline></p>
                <br />
                <p>The CCLA Client Portal (the “Portal”) is made available to clients of CCLA by CCLA Investment Management Limited which is authorised and regulated by the Financial Conduct Authority. The Portal provides access to account information. Nothing on the Portal constitutes the provision of financial, investment or other professional advice. Before making any investment decision you should read a fund’s key information document and prospectus/scheme particulars. CCLA strongly recommends you seek independent professional advice prior to making an investment decision. Please remember past performance is not a reliable indicator of future results. The value of investments and the income from them can go up and down in value and investors may get back less than they put in.</p>
                <p>In these Terms and Conditions (“Terms”), “we”, “our” and “us” means CCLA Investment Management Limited and “you” and “your” means any person who accesses and uses the Portal. </p>
                <br />
                <p>
                  1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Bold>General</Bold>
                </p>
                <p>1.1&nbsp;&nbsp;By accessing and using the Portal you agree to be bound by, and act in accordance with, these Terms. If you do not wish to be bound by these Terms, then you should cease such access and/or use immediately. If you breach any term of these Terms, then your right to access and use the Portal shall cease immediately.</p>
                <p>1.2&nbsp;&nbsp;This Portal is directed at residents of the United Kingdom. If you choose to use this Portal from locations outside the United Kingdom, you do so at your sole risk and you are responsible for compliance with all applicable local laws.</p>
                <p>1.3&nbsp;&nbsp;We give no warranty as to the uninterrupted availability of the Portal. We reserve the right to suspend, restrict or terminate your access to the Portal at any time.</p>
                <p>1.4&nbsp;&nbsp;We may from time to time amend the content on the Portal without notice.</p>
                <p>1.5&nbsp;&nbsp;We may amend these Terms from time to time without notice and such amendments will be effective from the date that they are posted on the Portal. Your continued use of the Portal will constitute your acknowledgement of the amended Terms.</p>
                <br />
                <p>
                  2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Bold>Use of the Portal</Bold>
                </p>
                <p>2.1&nbsp;&nbsp;You may not use the Portal:</p>
                <p>2.1.1&nbsp;&nbsp;in any unlawful, fraudulent or commercial manner, or in a way which infringes another person’s rights; or</p>
                <p>2.1.2&nbsp;&nbsp;in a way that interferes with, disrupts or imposes an unreasonable or disproportionately large burden on our communications and technical systems as determined by us.</p>
                <p>2.2&nbsp;&nbsp;You may not tamper with, modify, reverse engineer or amend any part of the Portal, nor operate a link to the Portal without our prior written permission. We may require you to immediately remove any link to the Portal at any time.</p>
                <br />
                <p>
                  3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Bold>Access to the Portal</Bold>
                </p>
                <p>3.1&nbsp;&nbsp;Access to the Portal is restricted to those users who have registered with us. We reserve the right to withdraw or amend the service we provide on the Portal without notice. The Portal may be suspended temporarily and without notice in circumstances of system failure, maintenance or repair or for reasons beyond our control.</p>
                <p>3.2&nbsp;&nbsp;The Portal is intended normally to be available 24 hours a day and 7 days a week. We will not be liable for any failure to achieve this level of availability. We will not be liable if for any reason the Portal is unavailable at any time or for any period.</p>
                <p>3.3&nbsp;&nbsp;You are responsible for making all arrangements necessary for you to have access to the Portal. </p>
                <p>3.4&nbsp;&nbsp;It is your responsibility to keep your login details secure. Login details or any additional security details unique to you and applicable to your use of the Portal must never be shared with anyone, and after accessing your account on the Portal, you must log off. This ensures that no third party may be able to access your account on the Portal. If you believe your security details have been accessed by another person you should contact Client Services at CCLA.</p>
                <br />
                <p>
                  4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Bold>Copyright</Bold>
                </p>
                <p>All copyright and other intellectual property rights in the materials on this site are owned by us or our affiliated companies.</p>
                <br />
                <p>
                  5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Bold>Limitation of liability</Bold>
                </p>
                <p>5.1&nbsp;&nbsp;Nothing in these Terms excludes or limits our liability for death or personal injury caused by our negligence or for our fraud or fraudulent misrepresentation, or for any matter for which it is not permitted by law to exclude or limit, nor do we seek to exclude our duties or liabilities under the Financial Services and Markets Act 2000.</p>
                <p>5.2&nbsp;&nbsp;Information provided on the Portal is for information only. Whilst we endeavour to ensure that all content on the Portal is accurate, information provided on the Portal should not be used for making investment decisions.  We do not accept responsibility or liability for any loss or damage which you may suffer or incur in respect of any information provided through the Portal.</p>
                <p>5.3&nbsp;&nbsp;We do not warrant or guarantee that the Portal is free from viruses, spyware, malicious software, trojans, worms, logic bombs or anything else which may have a harmful effect on any technology. You should use appropriate virus checking software. We will not be liable for any viruses, code, files or programs designed to interrupt, restrict, destroy or otherwise compromise the integrity of the Portal or any hardware on which it is hosted We are not responsible or liable for any loss or damage which you may suffer in this regard where caused by a third party.</p>
                <p>5.4&nbsp;&nbsp;We are not responsible or liable for any loss or damage which you may suffer from:</p>
                <p>5.4.1&nbsp;&nbsp;your use of the Portal (including any loss of savings you expect to make, loss of business or business opportunity, or loss of profit or revenue);</p>
                <p>5.4.2 your inability to access and use the Portal for any reason.</p>
                <br />
                <p>
                  6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Bold>Data Protection</Bold>
                </p>
                <p>We will process all personal data and information in accordance with our Privacy Policy. Full details of our Privacy Policy are available on our website</p>
                <br />
                <p>
                  7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Bold>Governing law</Bold>
                </p>
                <p>7.1&nbsp;&nbsp;These Terms and your access to and use of the Portal, along with any non-contractual obligations arising out of or in connection with these Terms, shall be governed by and interpreted in accordance with English law.</p>
                <p>7.2&nbsp;&nbsp;Each of you and us submits to the exclusive jurisdiction of the courts of England and Wales in connection with these Terms and your access to and use of the Portal (including any claims or disputes).</p>
              </BodyLabel>
            </Col>
            <Col marginbottom="lg">
              <RowCheckboxContainer>
                <TwoLineLabel style={{ maxWidth: '100%' }} textalign={media?.isPhone ? 'right' : 'center'}>
                  I have read CCLA Digital Portal Terms and Conditions
                </TwoLineLabel>
                <StyledCheckbox isChecked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
              </RowCheckboxContainer>
            </Col>
          </ScrollDiv>
        </BorderWrapper>
        <Row margintop={media?.isPhone ? 'md' : 'lg'}>
          <Col style={{ flexDirection: 'row' }} xs={12} textalign="center" justifycontent="center">
            <ButtonDecline onClick={handleDecline}>
              Decline
            </ButtonDecline>
            <ButtonAccept disabled={!isChecked} onClick={handleSubmit}>
              Accept
            </ButtonAccept>
          </Col>
        </Row>
      </StyledModal>
  );
};

export default TermsAndConditions;

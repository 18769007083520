import orderBy from 'lodash.orderby';

const SORT_KEYS = {
  NAME: 'name',
  INSTRUMENT: 'instrumentName',
  SUBDISTRIBUTOR: 'subdistributorName',
};

const SORT = {
  ASC: 'asc',
  DSC: 'desc',
};

const handleSortAccounts = ({ accounts, sort, sortKey }) => {
  if (!accounts?.length) return [];

  switch (sortKey) {
    case SORT_KEYS.NAME:
      return orderBy(accounts, ['name'], [sort]);
    case SORT_KEYS.INSTRUMENT:
      return orderBy(accounts, ['metadata.instrumentName'], [sort]);
    case SORT_KEYS.SUBDISTRIBUTOR:
      return orderBy(accounts, ['metadata.subdistributorName'], [sort]);
    case SORT_KEYS.DOCUMENTS:
      return orderBy(accounts, ['documents'], [sort]);
    default:
      return accounts;
  }
};

const ACCOUNTS = {
  SORT_KEYS, SORT, handleSortAccounts,
};

export default ACCOUNTS;

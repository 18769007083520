import orderBy from 'lodash.orderby';

const SORT_KEYS = {
  NAME: 'name',
  TA_ROLE: 'taRole',
  PORTAL_ROLE: 'portalRole',
};
const SORT = {
  ASC: 'asc',
  DSC: 'desc',
};

const handleSortPersons = ({ items, sort, sortKey }) => {
  if (!items?.length) return [];

  switch (sortKey) {
    case SORT_KEYS.NAME:
      return orderBy(items, ['fullName'], [sort]);
    case SORT_KEYS.TA_ROLE:
      return orderBy(items, ['taRoles'], [sort]);
    case SORT_KEYS.PORTAL_ROLE:
      return orderBy(items, ['portalRole'], [sort]);
    default:
      return items;
  }
};

const PERSONS_LIST = 'PERSONS_LIST';
const PERSON_DETAILS = 'PERSON_DETAILS';
const ACCOUNT_PAGE_MODAL = 'ACCOUNT_PAGE_MODAL';
const BLOCKED = 'Blocked';

const editPersonOrigin = {
  PERSONS_LIST, PERSON_DETAILS, ACCOUNT_PAGE_MODAL,
};

const getPersonActions = ({ id, userId, status }, history, dispatch, isRestricted = false) => [
  {
    key: `${id}-view`,
    label: 'View',
    onClick: () => history.push(`/person/${id}`),
  },
  {
    key: `${id}-block`,
    label: 'Block',
    hidden: status === BLOCKED || isRestricted,
    onClick: () => {
      dispatch.dashboard.SET_PROP({
        personToEdit: {
          id: userId, isBlocked: true, origin: editPersonOrigin.PERSONS_LIST,
        },
      });
    },
  },
  {
    key: `${id}-unblock`,
    label: 'Unblock',
    hidden: status !== BLOCKED || isRestricted,
    onClick: () => {
      dispatch.dashboard.editPerson({
        id: userId, isBlocked: false, origin: editPersonOrigin.PERSONS_LIST,
      });
    },
  },
];

const resolveStatus = (status) => {
  switch (status) {
    case 'Active':
    return 'Viewer';
    case 'Blocked':
    return 'Blocked';
    case 'Inactive':
    return 'Inactive';
    default:
    return '-';
  }
};

const formatTARole = (contract) => {
  const values = [];
  contract?.authorisingPerson && values.push('Authorising');
  contract?.contractCorrespondent && values.push('Correspondent');
  contract?.previousContractCorrespondent && values.push('Previous correspondent');
  contract?.signatory && values.push('Signatory');
  contract?.associate && values.push('Associate');
  contract?.auditor && values.push('Auditor');

  return values.join(', ');
};

const PERSONS = {
  editPersonOrigin, resolveStatus, getPersonActions, formatTARole, SORT_KEYS, SORT, handleSortPersons,
};

export default PERSONS;

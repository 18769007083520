/* eslint-disable consistent-return */
import { createModel } from '@rematch/core';
import { stringify } from 'qs';
import { addDays, getDateFromFormat } from '@allfundsbank/np-date';
import isEqual from 'lodash.isequal';
import { saveAs } from 'file-saver';
import { HttpInstance, displaySuccessMsg, displayErrorMsg, saveToStorage, loadFromStorage } from 'src/utils';
import { DASHBOARD, PERSONS } from 'src/helpers';
import { API_ROUTES, ORG_KEY, RESTRICTED_KEY } from 'src/enums';

const http = new HttpInstance();
const { getPeriodDates, getPeriodDateHelper } = DASHBOARD;
const { editPersonOrigin } = PERSONS;

const DEFAULT_LIMIT_TRANSACTIONS = 4;
const DEFAULT_LIMIT_PERSONS = 10;
const DEFAULT_PERIOD_OPTION = '5Y';
const PERIOD_SELECTOR_TAG = 'period';

const dashboard = createModel()({
  name: 'dashboard',
  state: {
    period: DEFAULT_PERIOD_OPTION,
    performanceSerie: [],
    benchmark: undefined,
    benchmarkSerie: [],
    benchmarkPerformance: {},
    benchmarkComposition: undefined,
    performance: {},
    summary: {},
    initialValuationDate: null,
    initialDate: {},
    positions: [],
    distributions: {},
    portfolioIds: null,
    transactions: [],
    transactionsTotal: 0,
    transactionsTypeFilter: [],
    selectedAccountCashEntries: {},
    cashEntriesFilter: {
      currentPage: 1,
      sortKey: 'date',
      sort: 'DSC'
    },
    cashEntries: [],
    cashEntriesTotal: 0,
    cashEntriesParams: {},
    reinvestmentParams: {},
    reinvestmentsFilter: {
      currentPage: 1,
      sortKey: 'date',
      sort: 'DSC'
    },
    reinvestment: [],
    reinvestmentTotal: 0,
    reinvestmentParams: {},
    ratios: [],
    noOrganisations: false,
    validPeriodRatios: true,
    organisations: [],
    selectedOrganisationId: undefined,
    accounts: [],
    accountsTotal: 0,
    users: [],
    instrumentDetails: {},
    filterSelectedAccounts: [],
    presetList: [],
    selectedPresetId: undefined,
    selectedAccounts: [],
    selectedTransactionTypes: [],
    faqs: [],
    filterPersons: {
      currentPage: 1,
      sortKey: 'name',
      sort: 'ASC',
      term: '',
    },
    filterTransactions: {
      currentPage: 1,
      sortKey: 'date',
      sort: 'DSC',
    },
  },
  reducers: {
    SET_PROP(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    RESET(state) {
      return {
        ...state,
        ratios: [],
        validPeriodRatios: true,
        instrumentDetails: {},
        selectedAccounts: [],
        presetList: [],
      };
    },
    RESET_ORG_DATA(state) {
      return {
        ...state,
        period: DEFAULT_PERIOD_OPTION,
        performanceSerie: [],
        performance: {},
        summary: {},
        initialDate: {},
        positions: [],
        distributions: {},
        portfolioIds: null,
        initialValuationDate: null,
        transactions: [],
        transactionsTotal: 0,
        cashEntriesFilter: {
          currentPage: 1,
          sortKey: 'date',
          sort: 'DSC'
        },
        reinvestmentParams: {},
        reinvestmentsFilter: {
          currentPage: 1,
          sortKey: 'date',
          sort: 'DSC'
        },
        reinvestment: [],
        reinvestmentTotal: 0,
        reinvestmentParams: {},
        cashEntries: [],
        cashEntriesTotal: 0,
        cashEntriesParams: {},
        accounts: [],
        accountsTotal: 0,
        users: [],
        filterSelectedAccounts: [],
        presetList: [],
        selectedAccounts: [],
        selectedAccountTransactions: {},
        selectedAccountCashEntries: {},
        filterPersons: {
          currentPage: 1,
          sortKey: 'name',
          sort: 'ASC',
          term: '',
        },
        filterTransactions: {
          currentPage: 1,
          sortKey: 'date',
          sort: 'DSC',
        },
        transactionsTypeFilter: [],
      };
    },
  },
  effects: (dispatch) => ({
    async setProp(payload) {
      dispatch.dashboard.SET_PROP(payload);
    },
    async syncState(payload, state) {
      if (payload) return state[payload];
      return state;
    },
    async updateTransactionPage({ currentPage }, state) {
      const filter = state.dashboard.filterTransactions
      await dispatch.dashboard.SET_PROP({ filterTransactions: { ...filter, currentPage } });
    },
    async updateCashEntriesPage({ currentPage }, state) {
      const filter = state.dashboard.cashEntriesFilter
      await dispatch.dashboard.SET_PROP({ cashEntriesFilter: { ...filter, currentPage } });
    },
    async updateReinvestmentPage({ currentPage }, state) {
      const filter = state.dashboard.reinvestmentsFilter
      await dispatch.dashboard.SET_PROP({ reinvestmentsFilter: { ...filter, currentPage } });
    },
    async loadTransactionsTypes(payload, state) {
      const response = await http.get(API_ROUTES.TRANSACTIONS_TYPES);
      if (!response?.data) return

      await dispatch.dashboard.SET_PROP({ transactionsTypeFilter: response.data });
    },
    async loadPage(payload, state) {
      await dispatch.dashboard.SET_PROP({ selectedTransactionTypes: [] });
      await dispatch.dashboard.loadOrganisations();
      await dispatch.dashboard.loadAccounts();
      await dispatch.dashboard.loadInitialDate();
      await dispatch.dashboard.loadTransactionsTypes();
      await dispatch.dashboard.loadSummary({});

      Promise.race([
        dispatch.dashboard.loadPerformance({}),
        dispatch.dashboard.loadPositions({}),
        dispatch.dashboard.loadPresets({}),
        dispatch.dashboard.loadTransactions({}),
        dispatch.dashboard.loadCashEntries({}),
        dispatch.dashboard.loadReinvestments({}),
      ]);
    },
    async loadFilterSelectedAccount(organisationId, state) {
      if (!organisationId) return;

      const params = { clientIds: [organisationId] };
      const response = await http.get(`${API_ROUTES.ACCOUNTS}`, params);
      await dispatch.dashboard.SET_PROP({ filterSelectedAccounts: response?.data || [] });
    },
    async createPreset({ name, selectedAccounts, selectedOrganisationId: presetOrganisationId }, state) {
      const { selectedOrganisationId } = state.dashboard;

      const { entity, id: userId } = state?.auth?.client;
      const body = {
        organisationId: presetOrganisationId,
        selectedAccounts,
        entity,
        userId,
        name,
      };
      try {
        const { id } = await http.post(API_ROUTES.PRESETS, body, { forceCrash: true });
        displaySuccessMsg('Preset saved successfully');
        await dispatch.dashboard.loadPresets({ force: true });

        if (presetOrganisationId === selectedOrganisationId) {
          return dispatch.dashboard.SET_PROP({ selectedPresetId: id });
        }

        await dispatch.dashboard.changeOrganisation({ id: presetOrganisationId });
        await dispatch.dashboard.SET_PROP({ selectedPresetId: id });

        const newSelectedAccounts = state.dashboard.presetList
          .find((preset) => preset.organisationId === state.dashboard.selectedOrganisationId)?.presets
          .find((p) => p.id === id)?.selectedAccounts;

        await dispatch.dashboard.SET_PROP({ selectedAccounts: newSelectedAccounts });
      } catch (error) {
        displayErrorMsg('Error saving preset');
      }
    },
    async removePreset({ id }, state) {
      if (!id) return;
      try {
        await http.delete(`${API_ROUTES.PRESETS}/${id}`, { force: true });
        const { selectedOrganisationId, selectedPresetId } = state.dashboard;

        const newPresetList = state.dashboard.presetList?.map((presetList) => {
          if (presetList?.organisationId !== selectedOrganisationId) return presetList;
          const filteredPresets = presetList?.presets.filter((item) => item?.id !== id);
          return { ...presetList, presets: filteredPresets };
        });
        await dispatch.dashboard.SET_PROP({ presetList: newPresetList });

        if (id === selectedPresetId) await dispatch.dashboard.SET_PROP({ selectedPresetId: undefined });
      } catch (e) {
        displayErrorMsg('Error deleting preset');
      }
    },
    async selectPreset({ key }, state) {
      if (!key) return;

      const newSelectedAccounts = state.dashboard.presetList
        .find((preset) => preset.organisationId === state.dashboard.selectedOrganisationId)?.presets
        .find((p) => p.id === key)?.selectedAccounts;

      await dispatch.dashboard.SET_PROP({
        selectedPresetId: key,
        selectedAccounts: newSelectedAccounts,
      });
    },
    async loadPresetAccounts(payload, state) {
      const { selectedPresetId, selectedOrganisationId, presetList } = state?.dashboard;
      if (!selectedPresetId || !selectedOrganisationId || !presetList?.length) await dispatch.dashboard.SET_PROP({ presetAccounts: [] });
      const selectedOrgPresetsList = presetList?.find((item) => item?.organisationId === selectedOrganisationId);
      const presetAccounts = selectedOrgPresetsList?.presets?.find((item) => item?.id === selectedPresetId)?.selectedAccounts;
      await dispatch.dashboard.SET_PROP({ presetAccounts });
    },
    async loadOrganisations(payload, state) {
      if (state.dashboard.organisations.length !== 0) return;
      const { data } = await http.get(API_ROUTES.ORGANISATIONS, { sort: 'ASC', sortKey: 'name' });
      if (!data || data?.length === 0) {
        await dispatch.dashboard.SET_PROP({ noOrganisations: true });
      } else {
        let selectedOrganisationId = data[0].organisationId;
        if (payload?.holderId) selectedOrganisationId = loadFromStorage(ORG_KEY);

        await dispatch.dashboard.SET_PROP({
          selectedOrganisationId,
          noOrganisations: false,
          organisations: data,
          selectedAccounts: [],
        });
        saveToStorage(ORG_KEY, selectedOrganisationId, addDays(1, new Date()), loadFromStorage(RESTRICTED_KEY));
      }
    },
    async loadPresets({ force = false }, state) {
      if (state.dashboard.presetList?.length !== 0 && !force) return;
      const { data } = await http.get(API_ROUTES.PRESETS);
      await dispatch.dashboard.SET_PROP({ presetList: data });
    },
    async loadFaq(payload, state) {
      if (state.dashboard.faqs.length !== 0) return;
      const response = await http.get(API_ROUTES.FAQS);
      const { data = [] } = response || {};
      await dispatch.dashboard.SET_PROP({ faqs: data });
    },
    async changeOrganisation({ id, reloadAccount = true }, state) {
      await dispatch.dashboard.RESET_ORG_DATA();
      await dispatch.dashboard.SET_PROP({
        selectedOrganisationId: id,
        selectedAccounts: [],
        selectedPresetId: null,
      });

      if (reloadAccount) await dispatch.dashboard.loadAccounts();
    },
    async loadAccounts(payload, state) {
      const { selectedOrganisationId, selectedPresetId, selectedAccounts } = state.dashboard;
      if (!selectedOrganisationId) return;
      const params = {};

      if (payload?.allAccounts) {
        params.clientIds = [selectedOrganisationId];
      } else if (payload?.id) {
        params['portfolioIds[]'] = payload.id;
      } else if (selectedPresetId) {
        params.presetId = selectedPresetId;
      } else if (selectedAccounts?.lenth) {
        params['portfolioIds[]'] = selectedAccounts;
      } else {
        params.clientIds = [selectedOrganisationId];
      }

      // Comparation between last params to avoid unnecessaries api calls
      if (isEqual(params, state?.dashboard?.paramsAccounts)) return;

      const response = await http.get(`${API_ROUTES.ACCOUNTS}`, params);
      const { data = [], metadata = {} } = response || {};

      await dispatch.dashboard.SET_PROP({
        accounts: data,
        accountsTotal: metadata?.pagination?.total || 0,
        paramsAccounts: params,
      });
    },
    async loadPerformance(payload, state) {
      const { selectedOrganisationId, selectedPresetId, selectedAccounts, initialValuationDate } = state.dashboard;
      if (!selectedOrganisationId) return;

      const { period } = state.dashboard;
      let { dateFrom, dateTo } = state.dashboard;
      if (period !== PERIOD_SELECTOR_TAG) {
        [dateFrom, dateTo] = getPeriodDates(period, { date: initialValuationDate });
      }

      const params = {
        dateFrom,
        dateTo,
      };

      if (selectedPresetId) {
        params.presetId = selectedPresetId;
      } else if (selectedAccounts?.length) {
        params['portfolioIds[]'] = selectedAccounts;
      } else {
        params.clientIds = [selectedOrganisationId];
      }

      // Comparation between last params to avoid unnecessaries api calls
      if (isEqual(params, state?.dashboard?.paramsPerformance)) return;

      const response = await http.get(API_ROUTES.POSITIONS_SERIE, params);
      const { data = [] } = response || {};

      await dispatch.dashboard.SET_PROP({ performanceSerie: data, paramsPerformance: params });
    },
    async loadSummary(payload, state) {
      const { period, selectedAccounts } = state.dashboard;
      const { selectedOrganisationId, selectedPresetId } = state?.dashboard;
      let { dateFrom, dateTo } = state.dashboard;

      const params = { dateFrom, dateTo };

      if (period !== PERIOD_SELECTOR_TAG) {
        [dateFrom, dateTo] = getPeriodDates(period, null);
        params.period = period?.toUpperCase();
        params.dateFrom = dateFrom;
        params.dateTo = dateTo;
      }

      if (selectedPresetId) {
        params.presetId = selectedPresetId;
      } else if (selectedAccounts?.length) {
        params['portfolioIds[]'] = selectedAccounts;
      } else {
        params.clientIds = [selectedOrganisationId];
      }

      // Comparation between last params to avoid unnecessaries api calls
      if (isEqual(params, state?.dashboard?.paramsSummary)) return;
      const summary = await http.get(API_ROUTES.SUMMARY, params);
      let initialValuationDate = null;
      if (summary?.initialValuationDate && summary?.initialValuationDate !== '') {
        const dateString = summary?.initialValuationDate;
        const year = +dateString.substring(0, 4);
        const month = +dateString.substring(4, 6);
        const day = +dateString.substring(6, 8);
        initialValuationDate = new Date(year, month - 1, day);
      }

      await dispatch.dashboard.SET_PROP({
        summary: { ...summary, initialDate: { dateAsString: dateFrom, date: getDateFromFormat(dateFrom, 'YYYYMMDD') } },
        paramsSummary: params,
        initialValuationDate,
      });
    },
    async loadPositions({ force = false }, state) {
      const { period, initialValuationDate } = state.dashboard;
      const { selectedOrganisationId, selectedPresetId, selectedAccounts } = state.dashboard;
      let { dateFrom, dateTo } = state.dashboard;

      const params = { dateFrom, dateTo };

      if (period !== PERIOD_SELECTOR_TAG) {
        [dateFrom, dateTo] = getPeriodDates(period, { date: initialValuationDate });
        params.period = period?.toUpperCase();
        params.dateFrom = dateFrom;
        params.dateTo = dateTo;
      }

      if (selectedPresetId) {
        params.presetId = selectedPresetId;
      } else if (selectedAccounts?.length) {
        params['portfolioIds[]'] = selectedAccounts;
      } else {
        params.clientId = selectedOrganisationId;
      }

      // Comparation between last params to avoid unnecessaries api calls
      if (isEqual(params, state?.dashboard?.paramsPositions) && !force) return;

      const response = await http.get(API_ROUTES.POSITIONS_AGGREGATE, params);
      const positions = response?.data?.sort((a, b) => b.weight - a.weight) || [];
      // await dispatch.theme.adjustPositionsCardHeight(positions?.length || 0);
      await dispatch.dashboard.SET_PROP({
        positions,
        paramsPositions: params,
      });
    },
    async loadTransactions({ portfolioIds = '', limit }, state) {
      const { period, initialValuationDate, initialDate, accounts, selectedPresetId, selectedAccounts, presetAccounts } = state?.dashboard;

      let resolvedPortfolioId;
      if (portfolioIds?.length) {
        resolvedPortfolioId = portfolioIds;
      } else if (selectedPresetId && presetAccounts?.length) {
        const accountId = presetAccounts[0];
        resolvedPortfolioId = accountId;
      } else if (selectedAccounts?.length) {
        const accountId = selectedAccounts[0];
        resolvedPortfolioId = accountId;
      } else {
        const accountId = accounts[0]?.id;
        resolvedPortfolioId = accountId;
      }

      if (!resolvedPortfolioId || !Object.keys(initialDate).length) return;

      let { dateFrom, dateTo } = state?.dashboard;
      if (period !== PERIOD_SELECTOR_TAG) [dateFrom, dateTo] = getPeriodDates(period, { date: initialValuationDate });

      const { selectedTransactionTypes, filterTransactions } = state?.dashboard;
      const transactionTypes = selectedTransactionTypes.map((it) => it.key);
      const { sort, sortKey, currentPage } = filterTransactions;
      const params = {
        skip: ((currentPage || 1) - 1) * DEFAULT_LIMIT_TRANSACTIONS || 0,
        limit: limit || DEFAULT_LIMIT_TRANSACTIONS,
        'portfolioIds[]': resolvedPortfolioId,
        sort,
        sortKey,
        transactionTypeLang: 'en',
        transactionTypeCodes: transactionTypes || undefined,
        'populates[]=': ['instruments', 'transactionTypes'],
        dateFrom,
        dateTo,
      };

      // Comparation between last params to avoid unnecessaries api calls
      if (isEqual(params, state?.dashboard?.paramsTransactions)) return;

      const selectedAccountTransactions = accounts?.find((item) => item.id === resolvedPortfolioId);
      const { data, metadata } = await http.get(API_ROUTES.TRANSACTIONS, params);
      await dispatch.dashboard.SET_PROP({
        transactions: data,
        transactionsTotal: metadata?.pagination?.total || 0,
        paramsTransactions: params,
        selectedAccountTransactions,
      });
    },
    async loadCashEntries({ id }, state) {
      const { currentPage, sort, sortKey } = state.dashboard.cashEntriesFilter;
      const { 
        period,
        initialValuationDate,
        initialDate,
        accounts,
        selectedPresetId,
        selectedAccounts,
        presetAccounts,
      } = state.dashboard;

      let { dateFrom, dateTo } = state?.dashboard;
      if (period !== PERIOD_SELECTOR_TAG) [dateFrom, dateTo] = getPeriodDateHelper(period, { date: initialValuationDate });

      let resolvedPortfolioId;
      if (id?.length) {
        resolvedPortfolioId = id;
      } else if (selectedPresetId && presetAccounts?.length) {
        const accountId = presetAccounts[0];
        resolvedPortfolioId = accountId;
      } else if (selectedAccounts?.length) {
        const accountId = selectedAccounts[0];
        resolvedPortfolioId = accountId;
      } else {
        const accountId = accounts[0]?.id;
        resolvedPortfolioId = accountId;
      }

      if (!resolvedPortfolioId || !Object.keys(initialDate).length) return;

      const params = {
        skip: ((currentPage || 1) - 1) * DEFAULT_LIMIT_TRANSACTIONS || 0,
        limit: DEFAULT_LIMIT_TRANSACTIONS,
        dateFrom,
        dateTo,
        'portfolioIds[]': resolvedPortfolioId,
        sort,
        sortKey
      };

      if (isEqual(params, state.dashboard.cashEntriesParams)) return;

      const response = await http.get(API_ROUTES.CASH_ENTRIES_SEARCH, params);
      const { data: cashEntries, metadata } = response || {};
      const selectedAccountCashEntries = accounts?.find((item) => item.id === resolvedPortfolioId);
      await dispatch.dashboard.SET_PROP({
        cashEntries,
        cashEntriesTotal: metadata?.pagination?.total || 0,
        cashEntriesParams: params,
        selectedAccountCashEntries
      });
    },
    async loadReinvestments({ id }, state) {
      const { currentPage, sort, sortKey } = state.dashboard.reinvestmentsFilter;
      const { 
        period,
        initialValuationDate,
        initialDate,
        accounts,
        selectedPresetId,
        selectedAccounts,
        presetAccounts,
      } = state.dashboard;
      let { dateFrom, dateTo } = state?.dashboard;
      if (period !== PERIOD_SELECTOR_TAG) [dateFrom, dateTo] = getPeriodDateHelper(period, { date: initialValuationDate });

      let resolvedPortfolioId;
      if (id?.length) {
        resolvedPortfolioId = id;
      } 
      else if (selectedPresetId && presetAccounts?.length) {
        const accountId = presetAccounts[0];
        resolvedPortfolioId = accountId;
      } else if (selectedAccounts?.length) {
        const accountId = selectedAccounts[0];
        resolvedPortfolioId = accountId;
      } else {
        const accountId = accounts[0]?.id;
        resolvedPortfolioId = accountId;
      }

      if (!resolvedPortfolioId || !Object.keys(initialDate).length) return;

      const params = {
        skip: ((currentPage || 1) - 1) * DEFAULT_LIMIT_TRANSACTIONS || 0,
        limit: DEFAULT_LIMIT_TRANSACTIONS,
        dateFrom,
        dateTo,
        'portfolioIds[]': resolvedPortfolioId,
        sort,
        sortKey
      };

      if (isEqual(params, state.dashboard.reinvestmentParams)) return;

      const response = await http.get(API_ROUTES.REINVESTMENTS_SEARCH, params);
      const { data: reinvestment, metadata } = response || {};
      const resolvedSelectedAccountReinvestment = accounts?.find((item) => item.id === resolvedPortfolioId);
      await dispatch.dashboard.SET_PROP({
        reinvestment,
        reinvestmentTotal: metadata?.pagination?.total || 0,
        reinvestmentParams: params,
        selectedAccountCashEntries: resolvedSelectedAccountReinvestment
      });
    },
    async loadInitialDate(portfolioIds, state) {
      const { selectedOrganisationId, selectedPresetId } = state.dashboard;
      const params = {
        skip: 0,
        limit: 1,
        sort: 'ASC',
        sortKey: 'date',
      };
      if (selectedPresetId) {
        params.presetId = selectedPresetId;
      } else {
        params.clientIds = [selectedOrganisationId];
      }

      // Comparation between last params to avoid unnecessaries api calls
      if (isEqual(params, state?.dashboard?.paramsInitialDate)) return;

      const { data } = await http.get(API_ROUTES.TRANSACTIONS, params);
      if (!data?.length) {
        if (state?.dashboard.accounts) {
          const orderedDates = state?.dashboard.accounts.sort((a, b) => Date.parse(a) > Date.parse(b));
          const olderDate = orderedDates[0]?.creationDate;
          const olderDateAsString = olderDate?.toString().slice(0, 10).replaceAll('-', '');
          await dispatch.dashboard.SET_PROP({
            initialDate: { date: olderDate, dateAsString: olderDateAsString },
            paramsInitialDate: { ...params, fromContract: true },
          });
        }
        return;
      }
      const [{ date, dateAsString }] = data;
      await dispatch.dashboard.SET_PROP({ initialDate: { date, dateAsString }, paramsInitialDate: params });
    },
    async loadInstrumentDetails({ instrumentId }, state) {
      await dispatch.dashboard.SET_PROP({ instrumentDetails: {} });
      if (!instrumentId) return;
      const { positions, instrument } = state?.dashboard?.positions?.find((item) => item?.instrumentId === instrumentId);
      const accounts = state?.dashboard?.accounts;
      const parsedPositions = positions?.map((item) => {
        const accountData = accounts.find((a) => a?.id === item?.portfolioId);
        return { ...item, accountData };
      });
      await dispatch.dashboard.SET_PROP({
        instrumentDetails: {
          instrumentId, positions: parsedPositions, instrument,
        },
      });
    },
    async loadPersons(payload, state, options = {}) {
      const { selectedOrganisationId } = state?.dashboard;
      if (!selectedOrganisationId) return;
      const { sort, sortKey, currentPage, term } = state?.dashboard?.filterPersons;
      const params = {
        skip: currentPage ? (currentPage - 1) * DEFAULT_LIMIT_PERSONS : 0,
        limit: DEFAULT_LIMIT_PERSONS,
        sort,
        sortKey,
        name: term || undefined,
      };

      // Comparation between last params to avoid unnecessaries api calls
      if (!options.edit && isEqual({ ...params, selectedOrganisationId }, state?.dashboard?.paramsPersons)) return;

      const { data, metadata } = await http.get(`${API_ROUTES.PERSONS_ORGANIZATION}/${selectedOrganisationId}`, params);
      await dispatch.dashboard.SET_PROP({
        persons: data,
        personsTotal: metadata?.pagination?.total || 0,
        paramsPersons: { ...params, selectedOrganisationId },
      });
    },
    async loadPersonDetails({ id }, state) {
      const personDetails = await http.get(`${API_ROUTES.PERSONS_DETAIL}/${id}/detail`);
      await dispatch.dashboard.SET_PROP({ personDetails });
    },
    async editPerson(payload, state) {
      const origin = payload?.origin || state.dashboard?.personToEdit?.origin;
      const id = payload?.id !== undefined ? payload.id : state.dashboard?.personToEdit?.id;
      if (!id) return;
      const isBlocked = payload?.isBlocked !== undefined ? payload.isBlocked : state.dashboard?.personToEdit?.isBlocked;
      const response = await http.put(`${API_ROUTES.USERS}/${id}/block`, { isBlocked });
      if (response) {
        const fullName = response?.metadata?.fullName || '';
        displaySuccessMsg(`User ${fullName} has been ${isBlocked ? 'blocked' : 'unblocked'}!`);
        await dispatch.dashboard.SET_PROP({ personToEdit: null });
        switch (origin) {
          case editPersonOrigin.PERSONS_LIST:
            dispatch.dashboard.loadPersons({}, { edit: true });
            break;
          case editPersonOrigin.PERSON_DETAILS:
            dispatch.dashboard.loadPersonDetails({ id: response.personId });
            break;
          case editPersonOrigin.ACCOUNT_PAGE_MODAL:
            dispatch.dashboard.loadAccountDetails({ id: state?.dashboard?.accountDetails?.id });
            break;
          default:
            break;
        }
      } else {
        displayErrorMsg(`Failed to ${isBlocked ? 'block' : 'unblock'}`);
      }
    },
    async loadAccountDetails({ id }, state) {
      if (!id) return;
      const { accounts } = state.dashboard;
      const accountDetails = accounts.find((item) => item.id === id);
      const details = await http.get(`${API_ROUTES.ACCOUNTS}/${id}/detail`);
      await dispatch.dashboard.SET_PROP({ accountDetails: { ...accountDetails, ...details } });
    },
    async sendEmail(id) {
      if (!id) return;
      try {
        await http.get(`${API_ROUTES.USERS}/${id}/reset-password`, {}, { forceCrash: true });
        displaySuccessMsg("An email has been sent to this user's email address", 5000);
      } catch (err) {
        if (err?.message) {
          displayErrorMsg(err.message, 5000);
          return;
        }

        displayErrorMsg('The email has not been sent due to an error. Please try again', 5000);
      }
    },
    async sendContactData(formData, state) {
      const { selectedOrganisationId, organisations } = state.dashboard;
      const currentOrganisation = organisations.find((o) => o.organisationId === selectedOrganisationId);
      const body = {
        ...formData,
        organisationName: currentOrganisation?.name || '-',
      };

      await http.post(API_ROUTES.CONTACT, body);
    },
    async downloadTransactionsExcel(options, state) {
      await dispatch.dashboard.downloadXlsx({ ...options });
    },
    async downloadCashEntriesExcel(options, state) {
      await dispatch.dashboard.downloadXlsx({ ...options });
    },
    async downloadHoldingsExcel(options, state) {
      await dispatch.dashboard.downloadXlsx({ ...options });
    },
    async downloadXlsx(options, state) {
      const {
        selectedOrganisationId,
        organisations,
        selectedPresetId,
        selectedAccounts,
        period,
        dateTo,
        dateFrom,
        summary,
      } = state.dashboard;

      const { initialValuationDate, latestValuationDate } = summary;

      const params = {
        period,
        initialValuationDate,
        latestValuationDate,
      };

      if (options.type === 'HOLDINGS') params['populates[]'] = 'contracts';
      if (options.type === 'TRANSACTIONS') {
        const { transactionType } = state?.dashboard?.filterTransactions;
        params.transactionType = transactionType;
        params.transactionTypeLang = 'en';
      }

      if (dateFrom && dateTo) {
        params.dateFrom = dateFrom;
        params.dateTo = dateTo;
      } else {
        const [initialDate, finalDate] = getPeriodDateHelper(period);
        params.dateFrom = initialDate;
        params.dateTo = finalDate;
      }

      if (selectedPresetId) {
        params.presetId = selectedPresetId;
      } else if (selectedAccounts?.length) {
        params['portfolioIds[]'] = selectedAccounts;
      } else {
        params.clientId = selectedOrganisationId;
      }

      const organisation = organisations.find((item) => item.organisationId === selectedOrganisationId);
      params.clientName = organisation && organisation.name;

      const queryParams = stringify(params);
      const file = await http.downloadBlob(`${options.apiRoute}?${queryParams}`, params);
      await saveAs(file, options.fileName);
    },
  }),
});

export default dashboard;

import styled from 'styled-components';

const StyledContainer = styled.div`
  font-size: 14px;
  opacity: 0.6;
  margin-top: 24px;
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 1140px;

  @media only screen and (min-width: 576px) and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 750px;
  }
`;

const Disclaimer = () => (
  <StyledContainer>
    Our client portal provides access to information on an investor’s accounts. Nothing on the portal constitutes the provision of financial, investment or other professional advice. Before deciding to invest please read a fund’s key information document and prospectus. CCLA strongly recommends you seek independent professional advice prior to making an investment decision. Please remember past performance is not a reliable indicator of future results. The value of investments and the income from them can go up and down in value and investors may get back less than they put in.
  </StyledContainer>
);

export default Disclaimer;

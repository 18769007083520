import styled from 'styled-components';

const LoginContainer = styled.div`
  position: absolute;
  height: 200px;
  z-index: 15;
  top: 40%;
  left: 48%;
  margin: -100px 0 0 -150px;
  width: 380px;
  max-width: 95%;
  @media only screen and (min-width: 360px) and (max-width: 575px) {
    left: 42%;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    left: 44%;
  }
`;

export default LoginContainer;
